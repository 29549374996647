var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image_flame"},[_c('draggable',_vm._b({staticClass:"image_box",attrs:{"handle":".list_num","force-fallback":true},model:{value:(_vm.uploadImage),callback:function ($$v) {_vm.uploadImage=$$v},expression:"uploadImage"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.uploadImage),function(image,index){return _c('div',{key:index,staticClass:"image_card"},[_c('v-btn',{staticClass:"list_num image_drag_btn",attrs:{"icon":"","color":"black"}},[_c('v-icon',[_vm._v("mdi-drag")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.uploadImage[index].is_enable),expression:"!uploadImage[index].is_enable"}],staticClass:"white_back"}),_c('v-card',{attrs:{"loading":"false","outlined":"","tile":"","width":"167"}},[(_vm.uploadImage[index].path)?_c('v-img',{attrs:{"height":"220px","src":_vm.uploadImage[index].path
              ? _vm.setting.cdn + _vm.uploadImage[index].path
              : ''}}):_c('v-img',{attrs:{"height":"220px","src":require("@/assets/images/no_image.jpg")}}),_c('div',{staticClass:"image_info pa-1"},[_c('div',[_c('span',[_vm._v("#"+_vm._s(index + 1))]),_c('span',[_vm._v(" "+_vm._s(_vm.uploadImage[index].type)+" "),_c('span',{staticClass:"mini"},[_vm._v(_vm._s(_vm.uploadImage[index].width ? "[W:" + _vm.uploadImage[index].width + "xH:" + _vm.uploadImage[index].height + "]" : ""))])])])]),_c('v-card-actions',{staticClass:"pa-1"},[_c('input',{ref:"upload",refInFor:true,staticStyle:{"display":"none"},attrs:{"name":"upload_file","type":"file","upload_index":index},on:{"change":_vm.onImageUploaded}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{on:{"click":function($event){return _vm.$refs.upload[index].click()}}},[_vm._v("mdi-upload ")])],1)]}}],null,true)},[_c('span',[_vm._v("画像アップロード")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.shop_site_id > 0),expression:"shop_site_id > 0"}],attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"copy_index":index},on:{"click":function($event){return _vm.onCopy($event)}}},[_vm._v("mdi-content-paste")])],1)]}}],null,true)},[_c('span',[_vm._v("全媒体共通の画像をコピー")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"disabled":!_vm.uploadImage[index].path,"color":_vm.uploadImage[index].is_enable ? 'info' : ''},on:{"click":function($event){return _vm.onMonitor(index)}}},[_vm._v(_vm._s(_vm.uploadImage[index].is_enable ? "mdi-toggle-switch" : "mdi-toggle-switch-off"))])],1)]}}],null,true)},[_c('span',[_vm._v("有効/無効")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{on:{"click":function($event){return _vm.onImageDelete(index)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("画像削除")])])],1)],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }