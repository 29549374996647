<template>
  <div>
    <v-app-bar flat color="white" class="x-scroll">
      <div class="pa-2">
        <v-btn class="gray font-weight-bold" @click="onBack"
          ><v-icon left>mdi-arrow-left-circle</v-icon>戻る</v-btn
        >
      </div>
      <div class="pa-2">
        <v-btn class="info font-weight-bold" @click="onSave"
          ><v-icon left>mdi-content-save</v-icon>保存</v-btn
        >
      </div>
      <div class="pa-2">
        <v-btn
          class="info font-weight-bold"
          :disabled="!cast_id"
          @click="onSync"
          ><v-icon left>mdi-sync</v-icon>保存して更新</v-btn
        >
      </div>
      <v-spacer></v-spacer>
      <div class="pa-2"></div>
    </v-app-bar>
  </div>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";

export default defineComponent({
  props: ["cast_id"],
  setup(props, ctx) {
    const onBack = async () => {
      ctx.emit("on_back");
    };

    const onSave = async () => {
      ctx.emit("on_save");
    };

    const onSync = async () => {
      ctx.emit("on_sync");
    };

    return {
      props,
      onBack,
      onSave,
      onSync,
    };
  },
});
</script>
