<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2">
                <span class="font-weight-bold">ショップコメント</span>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>お店コメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.description.text"
                      :rules="items.description.rules"
                    >
                      <v-textarea
                        ref="description"
                        v-model="cast_prop.description"
                        name="description"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.description.text"
                        :placeholder="`例：色白で笑顔の可愛らしい雰囲気を持ち、施術は、優しくとても丁寧な施術ですがしっかりとしたマッサージもできます。`"
                        rows="6"
                        outlined
                        :counter="items.description.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2">
                <span class="font-weight-bold">キャストコメント</span>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>キャストコメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.cast_pr.text"
                      :rules="items.cast_pr.rules"
                    >
                      <v-textarea
                        ref="cast_pr"
                        v-model="cast_prop.cast_pr"
                        name="cast_pr"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.cast_pr.text"
                        :placeholder="`例：はじめまして。レイナです。たくさんのお客様を癒すために日々、勉強中です♪`"
                        rows="6"
                        outlined
                        :counter="items.cast_pr.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><div class="font-weight-bold">セラピストの特徴</div>
                <div class="item-req">
                  <div>必須項目</div>
                </div>
              </v-col>
              <v-col cols="12" sm="10">
                セラピストの特徴を{{
                  items.therapist.counter
                }}個以内で選択してください。
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.therapist.text"
                  :rules="items.therapist.rules"
                >
                  <div v-if="!valid" class="red--text">
                    <span v-if="therapist_selected.length == 0"
                      >必須入力です。1個以上、選択してください。</span
                    >
                    <span
                      v-if="therapist_selected.length > items.therapist.counter"
                      >最大{{
                        items.therapist.counter
                      }}個まで選択できます。</span
                    >
                  </div>
                  <v-row class="mt-2 mb-2">
                    <v-col
                      v-for="therapist in therapist_type"
                      :key="therapist.label"
                      cols="4"
                      sm="3"
                      md="2"
                    >
                      <v-checkbox
                        class="mt-1"
                        ref="therapist"
                        v-model="therapist_selected"
                        name="therapist"
                        :label="therapist.label"
                        :value="therapist.value"
                        :success="valid"
                        :error="!valid"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">エステ歴</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="6" sm="3" md="2">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.experience.text"
                      :rules="items.experience.rules"
                    >
                      <v-text-field
                        ref="experience"
                        v-model="cast_prop.experience"
                        name="experience"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.experience.text"
                        suffix="年"
                        outlined
                        :counter="items.experience.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">資格</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.qualified.text"
                      :rules="items.qualified.rules"
                    >
                      <v-textarea
                        ref="qualified"
                        v-model="cast_prop.qualified"
                        name="qualified"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.qualified.text"
                        :placeholder="
                          `例：日本エステティック協会「認定エステティシャン」\n` +
                          `CIDESCO-NIPPONの「CIDESCOディプロマ」\n` +
                          `日本エステティック業協会「AEA認定インターナショナルエステティシャン」`
                        "
                        rows="6"
                        outlined
                        :counter="items.qualified.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">外部サービスPR</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.blog.text"
                      :rules="items.blog.rules"
                    >
                      <v-text-field
                        ref="blog"
                        v-model="cast_prop.blog"
                        name="blog"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.blog.text"
                        prefix="URL"
                        hint="例：http://ameblo.jp/●●●●/"
                        persistent-hint
                        outlined
                        :counter="items.blog.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.twitter.text"
                      :rules="items.twitter.rules"
                    >
                      <v-text-field
                        ref="twitter"
                        v-model="cast_prop.twitter"
                        name="twitter"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.twitter.text"
                        prefix="URL"
                        hint="例：https://twitter.com/●●●●/"
                        persistent-hint
                        outlined
                        :counter="items.twitter.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.instagram.text"
                      :rules="items.instagram.rules"
                    >
                      <v-text-field
                        ref="instagram"
                        v-model="cast_prop.instagram"
                        name="instagram"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.instagram.text"
                        prefix="URL"
                        hint="例：https://www.instagram.com/●●●●/"
                        persistent-hint
                        outlined
                        :counter="items.instagram.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const state = reactive({
      errorElement: null,
      cast_prop: {},
      therapist_selected: [],
      therapist_type: {
        "therapist-id-1": { label: "新人", value: "1" },
        "therapist-id-2": { label: "経験豊富", value: "2" },
        "therapist-id-3": { label: "業界未経験", value: "3" },
        "therapist-id-28": { label: "施術上手", value: "28" },
        "therapist-id-25": { label: "上品", value: "25" },
        "therapist-id-4": { label: "甘えん坊", value: "4" },
        "therapist-id-5": { label: "おとなしい", value: "5" },
        "therapist-id-7": { label: "おっとり", value: "7" },
        "therapist-id-8": { label: "明るい", value: "8" },
        "therapist-id-32": { label: "優しい", value: "32" },
        "therapist-id-30": { label: "努力家", value: "30" },
        "therapist-id-27": { label: "礼儀正しい", value: "27" },
        "therapist-id-9": { label: "清楚系", value: "9" },
        "therapist-id-10": { label: "天然系", value: "10" },
        "therapist-id-11": { label: "セクシー系", value: "11" },
        "therapist-id-12": { label: "お姉様系", value: "12" },
        "therapist-id-29": { label: "お嬢様系", value: "29" },
        "therapist-id-19": { label: "ギャル系", value: "19" },
        "therapist-id-20": { label: "美人系", value: "20" },
        "therapist-id-21": { label: "熟女系", value: "21" },
        "therapist-id-22": { label: "かわいい系", value: "22" },
        "therapist-id-24": { label: "アイドル系", value: "24" },
        "therapist-id-23": { label: "癒し系", value: "23" },
        "therapist-id-26": { label: "妹系", value: "26" },
        "therapist-id-16": { label: "モデル体型", value: "16" },
        "therapist-id-31": { label: "小柄", value: "31" },
        "therapist-id-18": { label: "色白肌", value: "18" },
      },
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
    });

    const items = {
      "description": {
        text: "ショップコメント",
        rules: "max:500",
        counter: "500",
      },
      "cast_pr": {
        text: "キャストコメント",
        rules: "max:500",
        counter: "500",
      },
      "therapist": {
        text: "セラピストの特徴",
        rules: "required|check_max:4",
        counter: "4",
      },
      "experience": {
        text: "エステ歴",
        rules: "numeric|max:3",
        counter: "3",
      },
      "qualified": {
        text: "資格",
        rules: "max:200",
        counter: "200",
      },
      "blog": {
        text: "アメーバブログやLivedoorブログ等の外部ブログ",
        rules: "url|max:100",
        counter: "100",
      },
      "twitter": {
        text: "Twitter",
        rules: "url|max:100",
        counter: "100",
      },
      "instagram": {
        text: "Instagram",
        rules: "url|max:100",
        counter: "100",
      },
    };

    const init = async () => {
      // cast_propにキーを設定
      for (let key in items) {
        set(state.cast_prop, key, null);
      }
      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        set(state.cast_prop, key, props.com_data.cast_prop[key]);
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);

      // セラピストの特徴をjsonから配列に変換しておく
      if (state.cast_prop.therapist) {
        state.therapist_selected = JSON.parse(state.cast_prop.therapist);
      }
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      state.errorElement = null;
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        const errorKey = utilFunc.getKeyByItemText(items, item_name);
        if (errorKey == "therapist") {
          state.errorElement = ctx.refs[errorKey][0].$el;
        } else {
          state.errorElement = ctx.refs[errorKey].$el;
        }
      }
      return isValid;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    // 保存処理
    const save = async () => {
      // 更新前と比べて差分があれば更新処理
      for (let key in state.cast_prop) {
        if (key == "therapist") {
          set(
            state.cast_prop,
            key,
            state.therapist_selected.length == 0
              ? ""
              : JSON.stringify(state.therapist_selected)
          );
        }
      }
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          prop_param.push({
            cast_id: props.com_data.cast_id,
            key: props.com_data.prefix + "__" + key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:estama.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    return {
      props,
      setting,
      items,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
    };
  },
});
</script>

<style scoped></style>
