<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2">
                <div class="font-weight-bold mb-2">キャストコメント</div>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>キャストコメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col col="12">
                    <div class="mt-1 mb-1">
                      <span class="font-weight-bold text-align-middle"
                        >HTML用&nbsp;</span
                      ><v-btn
                        text
                        color="primary"
                        class="font-weight-bold pa-1 d-inline"
                        @click="onHtmlClear('cast')"
                        >HTMLをクリア</v-btn
                      >
                    </div>
                    <div class="">
                      <ckeditor-component
                        ref="refCommentByCastCkeditor"
                        :data="cast_prop.prf_3"
                      ></ckeditor-component>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2">
                <div class="font-weight-bold mb-2">ショップコメント</div>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>お店コメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col col="12">
                    <div class="mt-1 mb-1">
                      <span class="font-weight-bold text-align-middle"
                        >HTML用&nbsp;</span
                      ><v-btn
                        text
                        color="primary"
                        class="font-weight-bold pa-1 d-inline"
                        @click="onHtmlClear('shop')"
                        >HTMLをクリア</v-btn
                      >
                    </div>
                    <div class="">
                      <ckeditor-component
                        ref="refCommentByShopCkeditor"
                        :data="cast_prop.prf_5"
                      ></ckeditor-component>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold"
                  >新着マーク<br />指定日まで表示</span
                ></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="4" sm="3" md="2">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.prf_7_Y.text"
                      :rules="items.prf_7_Y.rules"
                    >
                      <v-text-field
                        ref="prf_7_Y"
                        v-model="cast_prop.prf_7_Y"
                        name="prf_7_Y"
                        :error-messages="errors"
                        :success="valid"
                        :suffix="items.prf_7_Y.text"
                        outlined
                        :counter="items.prf_7_Y.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4" sm="3" md="2">
                    <v-select
                      ref="prf_7_M"
                      v-model="cast_prop.prf_7_M"
                      name="prf_7_M"
                      :items="selectItem.monthItem"
                      :no-data-text="items.prf_7_M.text"
                      :suffix="items.prf_7_M.text"
                      success
                      outlined
                      background-color="white"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" sm="3" md="2">
                    <v-select
                      ref="prf_7_D"
                      v-model="cast_prop.prf_7_D"
                      name="prf_7_D"
                      :items="selectItem.dayItem"
                      :no-data-text="items.prf_7_D.text"
                      :suffix="items.prf_7_D.text"
                      success
                      outlined
                      background-color="white"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";
import selectItem from "@/common/selectItem.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const refCommentByShopCkeditor = ref(null);
    const refCommentByCastCkeditor = ref(null);
    const state = reactive({
      errorElement: null,
      cast_prop: {},
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
    });

    const items = {
      "prf_3": {
        text: "キャストコメント",
        rules: "",
        counter: "",
      },
      "prf_5": {
        text: "ショップコメント",
        rules: "",
        counter: "",
      },
      "prf_7_Y": {
        text: "年",
        rules: "numeric|length:4",
        counter: "4",
      },
      "prf_7_M": {
        text: "月",
        rules: "",
        counter: "",
      },
      "prf_7_D": {
        text: "日",
        rules: "",
        counter: "",
      },
    };

    const init = async () => {
      // cast_propにキーを設定
      for (let key in items) {
        set(state.cast_prop, key, null);
      }
      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        if (key == "prf_7_M" || key == "prf_7_D") {
          set(state.cast_prop, key, Number(props.com_data.cast_prop[key]));
        } else {
          set(state.cast_prop, key, props.com_data.cast_prop[key]);
        }
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      state.errorElement = null;
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        const errorKey = utilFunc.getKeyByItemText(items, item_name);
        state.errorElement = ctx.refs[errorKey].$el;
      }
      return isValid;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    const save = async () => {
      // 更新前と比べて差分があれば更新処理
      for (let key in state.cast_prop) {
        if (key == "prf_5") {
          set(state.cast_prop, key, refCommentByShopCkeditor.value.editorData);
        }
        if (key == "prf_3") {
          set(state.cast_prop, key, refCommentByCastCkeditor.value.editorData);
        }
      }
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          prop_param.push({
            cast_id: props.com_data.cast_id,
            key: props.com_data.prefix + "__" + key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:cast_manager_wordpress.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    const onHtmlClear = (mode) => {
      let mode_label = "";
      if (mode == "shop") {
        mode_label = "ショップコメント";
      } else {
        mode_label = "キャストコメント";
      }
      if (
        !window.confirm(
          mode_label + "のHTMLに入力された内容をクリアします。よろしいですか？"
        )
      ) {
        return;
      }
      if (mode == "shop") {
        state.cast_prop.prf_5 = "";
        if (refCommentByShopCkeditor.value) {
          refCommentByShopCkeditor.value.editorData = "";
        }
      } else {
        state.cast_prop.prf_3 = "";
        if (refCommentByCastCkeditor.value) {
          refCommentByCastCkeditor.value.editorData = "";
        }
      }
    };

    return {
      props,
      selectItem,
      setting,
      items,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
      refCommentByShopCkeditor,
      refCommentByCastCkeditor,
      onHtmlClear,
    };
  },
});
</script>

<style scoped></style>
