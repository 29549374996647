<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="2"
              ><span class="font-weight-bold">コンテンツ</span></v-col
            >
            <v-col cols="12" sm="10">
              <div style="padding: 6px">
                <ckeditor-component
                  ref="refCkeditor"
                  :data="cast_prop.content"
                ></ckeditor-component>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const refCkeditor = ref();
    const state = reactive({
      errorElement: null,
      cast_prop: {},
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
    });

    const items = {
      "content": {
        text: "コンテンツ",
        rules: "",
        counter: "",
      },
    };

    const init = async () => {
      // cast_propにキーを設定
      for (let key in items) {
        set(state.cast_prop, key, null);
      }
      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        set(state.cast_prop, key, props.com_data.cast_prop[key]);
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      return true;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    const save = async () => {
      // 更新前と比べて差分があれば更新処理
      for (let key in state.cast_prop) {
        if (key == "content") {
          set(state.cast_prop, key, refCkeditor.value.editorData);
        }
      }
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          prop_param.push({
            cast_id: props.com_data.cast_id,
            key: props.com_data.prefix + "__" + key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:cast_manager_wordpress.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    return {
      props,
      setting,
      refCkeditor,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
    };
  },
});
</script>

<style scoped></style>
