var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('only-site-card',{attrs:{"com_data":_vm.com_data},scopedSlots:_vm._u([{key:"item_edit",fn:function(){return [_c('ValidationObserver',{ref:"observer"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("メッセージ")]),_c('p',{staticClass:"red--text mini"},[_vm._v(" 未入力の場合、コメントタブの「"),_c('strong',[_vm._v("お店のコメント")]),_vm._v("」の内容で更新します。 ")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.comment.text,"rules":_vm.items.comment.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"comment",attrs:{"name":"comment","error-messages":errors,"success":valid,"label":_vm.items.comment.text,"rows":"8","outlined":"","counter":_vm.items.comment.counter,"background-color":"white"},model:{value:(_vm.cast_prop.comment),callback:function ($$v) {_vm.$set(_vm.cast_prop, "comment", $$v)},expression:"cast_prop.comment"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("キャストからのメッセージ")]),_c('p',{staticClass:"red--text mini"},[_vm._v(" 未入力の場合、コメントタブの「"),_c('strong',[_vm._v("キャストコメント")]),_vm._v("」の内容で更新します。 ")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.additional_info.text,"rules":_vm.items.additional_info.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"additional_info",attrs:{"name":"additional_info","error-messages":errors,"success":valid,"label":_vm.items.additional_info.text,"rows":"8","outlined":"","counter":_vm.items.additional_info.counter,"background-color":"white"},model:{value:(_vm.cast_prop.additional_info),callback:function ($$v) {_vm.$set(_vm.cast_prop, "additional_info", $$v)},expression:"cast_prop.additional_info"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("女の子本人のツイッター")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.twitter_url.text,"rules":_vm.items.twitter_url.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"twitter_url",attrs:{"name":"twitter_url","error-messages":errors,"success":valid,"label":_vm.items.twitter_url.text,"persistent-hint":"","outlined":"","hint":"https://twitter.com/**** または @****(5文字〜15文字) の形式で入力","counter":_vm.items.twitter_url.counter,"background-color":"white"},model:{value:(_vm.cast_prop.twitter_url),callback:function ($$v) {_vm.$set(_vm.cast_prop, "twitter_url", $$v)},expression:"cast_prop.twitter_url"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("女の子本人のYouTubeチャンネル")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.youtube_url.text,"rules":_vm.items.youtube_url.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"youtube_url",attrs:{"name":"youtube_url","error-messages":errors,"success":valid,"label":_vm.items.youtube_url.text,"persistent-hint":"","outlined":"","hint":"https://www.youtube.com/**** の形式で入力","counter":_vm.items.youtube_url.counter,"background-color":"white"},model:{value:(_vm.cast_prop.youtube_url),callback:function ($$v) {_vm.$set(_vm.cast_prop, "youtube_url", $$v)},expression:"cast_prop.youtube_url"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("女の子本人のインスタグラム")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.instagram_url.text,"rules":_vm.items.instagram_url.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"instagram_url",attrs:{"name":"instagram_url","error-messages":errors,"success":valid,"label":_vm.items.instagram_url.text,"persistent-hint":"","outlined":"","hint":"https://instagram.com/**** の形式で入力","counter":_vm.items.instagram_url.counter,"background-color":"white"},model:{value:(_vm.cast_prop.instagram_url),callback:function ($$v) {_vm.$set(_vm.cast_prop, "instagram_url", $$v)},expression:"cast_prop.instagram_url"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("女の子本人のブログ")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.blog_url.text,"rules":_vm.items.blog_url.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"blog_url",attrs:{"name":"blog_url","error-messages":errors,"success":valid,"label":_vm.items.blog_url.text,"persistent-hint":"","outlined":"","hint":"https://**** の形式で入力","counter":_vm.items.blog_url.counter,"background-color":"white"},model:{value:(_vm.cast_prop.blog_url),callback:function ($$v) {_vm.$set(_vm.cast_prop, "blog_url", $$v)},expression:"cast_prop.blog_url"}})]}}])})],1)],1)],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }