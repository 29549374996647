var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('only-site-card',{attrs:{"com_data":_vm.com_data},scopedSlots:_vm._u([{key:"item_edit",fn:function(){return [_c('ValidationObserver',{ref:"observer"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("ショップコメント")]),_c('p',{staticClass:"red--text mini"},[_vm._v(" 未入力の場合、コメントタブの「"),_c('strong',[_vm._v("お店コメント")]),_vm._v("」の内容で更新します。 ")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.description.text,"rules":_vm.items.description.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"description",attrs:{"name":"description","error-messages":errors,"success":valid,"label":_vm.items.description.text,"placeholder":"例：色白で笑顔の可愛らしい雰囲気を持ち、施術は、優しくとても丁寧な施術ですがしっかりとしたマッサージもできます。","rows":"6","outlined":"","counter":_vm.items.description.counter,"background-color":"white"},model:{value:(_vm.cast_prop.description),callback:function ($$v) {_vm.$set(_vm.cast_prop, "description", $$v)},expression:"cast_prop.description"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-8"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("キャストコメント")]),_c('p',{staticClass:"red--text mini"},[_vm._v(" 未入力の場合、コメントタブの「"),_c('strong',[_vm._v("キャストコメント")]),_vm._v("」の内容で更新します。 ")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.cast_pr.text,"rules":_vm.items.cast_pr.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"cast_pr",attrs:{"name":"cast_pr","error-messages":errors,"success":valid,"label":_vm.items.cast_pr.text,"placeholder":"例：はじめまして。レイナです。たくさんのお客様を癒すために日々、勉強中です♪","rows":"6","outlined":"","counter":_vm.items.cast_pr.counter,"background-color":"white"},model:{value:(_vm.cast_prop.cast_pr),callback:function ($$v) {_vm.$set(_vm.cast_prop, "cast_pr", $$v)},expression:"cast_prop.cast_pr"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-8"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("セラピストの特徴")]),_c('div',{staticClass:"item-req"},[_c('div',[_vm._v("必須項目")])])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_vm._v(" セラピストの特徴を"+_vm._s(_vm.items.therapist.counter)+"個以内で選択してください。 "),_c('ValidationProvider',{attrs:{"name":_vm.items.therapist.text,"rules":_vm.items.therapist.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [(!valid)?_c('div',{staticClass:"red--text"},[(_vm.therapist_selected.length == 0)?_c('span',[_vm._v("必須入力です。1個以上、選択してください。")]):_vm._e(),(_vm.therapist_selected.length > _vm.items.therapist.counter)?_c('span',[_vm._v("最大"+_vm._s(_vm.items.therapist.counter)+"個まで選択できます。")]):_vm._e()]):_vm._e(),_c('v-row',{staticClass:"mt-2 mb-2"},_vm._l((_vm.therapist_type),function(therapist){return _c('v-col',{key:therapist.label,attrs:{"cols":"4","sm":"3","md":"2"}},[_c('v-checkbox',{ref:"therapist",refInFor:true,staticClass:"mt-1",attrs:{"name":"therapist","label":therapist.label,"value":therapist.value,"success":valid,"error":!valid,"hide-details":""},model:{value:(_vm.therapist_selected),callback:function ($$v) {_vm.therapist_selected=$$v},expression:"therapist_selected"}})],1)}),1)]}}])})],1)],1),_c('v-divider',{staticClass:"mt-4 mb-8"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("エステ歴")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3","md":"2"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.experience.text,"rules":_vm.items.experience.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"experience",attrs:{"name":"experience","error-messages":errors,"success":valid,"label":_vm.items.experience.text,"suffix":"年","outlined":"","counter":_vm.items.experience.counter,"background-color":"white"},model:{value:(_vm.cast_prop.experience),callback:function ($$v) {_vm.$set(_vm.cast_prop, "experience", $$v)},expression:"cast_prop.experience"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-8"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("資格")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.qualified.text,"rules":_vm.items.qualified.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"qualified",attrs:{"name":"qualified","error-messages":errors,"success":valid,"label":_vm.items.qualified.text,"placeholder":"例：日本エステティック協会「認定エステティシャン」\n" +
                        "CIDESCO-NIPPONの「CIDESCOディプロマ」\n" +
                        "日本エステティック業協会「AEA認定インターナショナルエステティシャン」","rows":"6","outlined":"","counter":_vm.items.qualified.counter,"background-color":"white"},model:{value:(_vm.cast_prop.qualified),callback:function ($$v) {_vm.$set(_vm.cast_prop, "qualified", $$v)},expression:"cast_prop.qualified"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-8"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("外部サービスPR")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.blog.text,"rules":_vm.items.blog.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('v-text-field',{ref:"blog",attrs:{"name":"blog","error-messages":errors,"success":valid,"label":_vm.items.blog.text,"prefix":"URL","hint":"例：http://ameblo.jp/●●●●/","persistent-hint":"","outlined":"","counter":_vm.items.blog.counter,"background-color":"white"},model:{value:(_vm.cast_prop.blog),callback:function ($$v) {_vm.$set(_vm.cast_prop, "blog", $$v)},expression:"cast_prop.blog"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.twitter.text,"rules":_vm.items.twitter.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('v-text-field',{ref:"twitter",attrs:{"name":"twitter","error-messages":errors,"success":valid,"label":_vm.items.twitter.text,"prefix":"URL","hint":"例：https://twitter.com/●●●●/","persistent-hint":"","outlined":"","counter":_vm.items.twitter.counter,"background-color":"white"},model:{value:(_vm.cast_prop.twitter),callback:function ($$v) {_vm.$set(_vm.cast_prop, "twitter", $$v)},expression:"cast_prop.twitter"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.instagram.text,"rules":_vm.items.instagram.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('v-text-field',{ref:"instagram",attrs:{"name":"instagram","error-messages":errors,"success":valid,"label":_vm.items.instagram.text,"prefix":"URL","hint":"例：https://www.instagram.com/●●●●/","persistent-hint":"","outlined":"","counter":_vm.items.instagram.counter,"background-color":"white"},model:{value:(_vm.cast_prop.instagram),callback:function ($$v) {_vm.$set(_vm.cast_prop, "instagram", $$v)},expression:"cast_prop.instagram"}})]}}])})],1)],1)],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }