<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">セラピストＱ＆Ａ</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col v-for="item in items" :key="item.item_id" cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="item.text"
                      :rules="item.rules"
                    >
                      <div
                        class="text-subtitle-1 black--text font-weight-bold mb-2"
                      >
                        {{ item.question }}
                      </div>
                      <v-text-field
                        ref="refQa"
                        v-model="cast_prop[item.item_id]"
                        :name="item.item_id"
                        :error-messages="errors"
                        :success="valid"
                        :label="item.text"
                        :placeholder="item.placeholder"
                        outlined
                        :counter="item.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const refQa = ref();
    const state = reactive({
      errorElement: null,
      cast_prop: {},
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
    });

    const items = {
      "qa_a1": {
        item_id: "qa_a1",
        question: "得意な施術",
        text: "回答1",
        rules: "max:20",
        counter: "20",
        placeholder: "例：鼠径部マッサージ",
      },
      "qa_a2": {
        item_id: "qa_a2",
        question: "好きな食べ物",
        text: "回答2",
        rules: "max:20",
        counter: "20",
        placeholder: "例：チョコクロ",
      },
      "qa_a3": {
        item_id: "qa_a3",
        question: "好きな男性のタイプ",
        text: "回答3",
        rules: "max:20",
        counter: "20",
        placeholder: "例：やさしい人",
      },
      "qa_a4": {
        item_id: "qa_a4",
        text: "回答4",
        question: "似ている芸能人",
        rules: "max:20",
        counter: "20",
        placeholder: "例：深田恭子",
      },
      "qa_a5": {
        item_id: "qa_a5",
        question: "休みの日は何してる？",
        text: "回答5",
        rules: "max:20",
        counter: "20",
        placeholder: "例：ショッピング　お菓子作り",
      },
      "qa_a6": {
        item_id: "qa_a6",
        question: "趣味・特技",
        text: "回答6",
        rules: "max:20",
        counter: "20",
        placeholder: "例：ポケモンＧＯ　ネイル",
      },
    };

    const init = async () => {
      // cast_propにキーを設定
      for (let key in items) {
        set(state.cast_prop, key, null);
      }
      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        set(state.cast_prop, key, props.com_data.cast_prop[key]);
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      state.errorElement = null;
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        const errorKey = utilFunc.getKeyByItemLabel(refQa.value, item_name);
        state.errorElement = refQa.value[errorKey].$el;
      }
      return isValid;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    // 保存処理
    const save = async () => {
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          prop_param.push({
            cast_id: props.com_data.cast_id,
            key: props.com_data.prefix + "__" + key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:estama.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    return {
      props,
      setting,
      items,
      refQa,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
    };
  },
});
</script>

<style scoped></style>
