<template>
  <div class="image_flame">
    <draggable
      v-model="uploadImage"
      v-bind="dragOptions"
      handle=".list_num"
      :force-fallback="true"
      class="image_box"
    >
      <div
        v-for="(image, index) in uploadImage"
        :key="index"
        class="image_card"
      >
        <v-btn icon class="list_num image_drag_btn" color="black">
          <v-icon>mdi-drag</v-icon>
        </v-btn>
        <div v-show="!uploadImage[index].is_enable" class="white_back"></div>
        <v-card loading="false" outlined tile width="167">
          <v-img
            height="220px"
            :src="
              uploadImage[index].path
                ? setting.cdn + uploadImage[index].path
                : ''
            "
            v-if="uploadImage[index].path"
          >
          </v-img>
          <v-img
            height="220px"
            src="@/assets/images/no_image.jpg"
            v-else
          ></v-img>
          <div class="image_info pa-1">
            <div>
              <span>#{{ index + 1 }}</span
              ><span
                >&nbsp;{{ uploadImage[index].type }}&nbsp;<span class="mini">{{
                  uploadImage[index].width
                    ? "[W:" +
                      uploadImage[index].width +
                      "xH:" +
                      uploadImage[index].height +
                      "]"
                    : ""
                }}</span></span
              >
            </div>
          </div>
          <v-card-actions class="pa-1">
            <input
              name="upload_file"
              ref="upload"
              type="file"
              style="display: none"
              :upload_index="index"
              @change="onImageUploaded"
            />
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon @click="$refs.upload[index].click()"
                    >mdi-upload
                  </v-icon>
                </v-btn>
              </template>
              <span>画像アップロード</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon v-show="shop_site_id > 0">
                  <v-icon @click="onCopy($event)" :copy_index="index"
                    >mdi-content-paste</v-icon
                  >
                </v-btn>
              </template>
              <span>全媒体共通の画像をコピー</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon
                    @click="onMonitor(index)"
                    :disabled="!uploadImage[index].path"
                    :color="uploadImage[index].is_enable ? 'info' : ''"
                    >{{
                      uploadImage[index].is_enable
                        ? "mdi-toggle-switch"
                        : "mdi-toggle-switch-off"
                    }}</v-icon
                  >
                </v-btn>
              </template>
              <span>有効/無効</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon @click="onImageDelete(index)">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>画像削除</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </div>
    </draggable>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import draggable from "vuedraggable";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";

export default {
  components: {
    draggable,
  },
  props: ["img_num", "cast_id", "shop_site_id", "image_list"],
  setup(props, ctx) {
    const fileRepository = repositoryFactory.get("file");

    const state = reactive({
      uploadImage: [],
    });

    const onMonitor = (index) => {
      const tmp = state.uploadImage[index];
      tmp.is_enable = !tmp.is_enable;
      set(state.uploadImage, index, tmp);
    };

    const onImageDelete = (index) => {
      set(state.uploadImage, index, {
        "path": null,
        "type": null,
        "width": null,
        "height": null,
        "is_enable": true,
      });
    };

    const uploadServer = async (index, imgObj, image) => {
      // if (state.uploadImage[index].image_base64) {
      if (imgObj.src) {
        // ここで画像保存処理(サーバにアップロードする)
        let ext = image.type.substr(image.type.indexOf("/") + 1);
        if (ext == "jpeg") {
          ext = "jpg";
        }
        const d = new Date();
        // アップロード用のファイル名を生成
        const file_name =
          "_tmp_" +
          store.getters["shops/currentShop"].id +
          "_" +
          d.getTime() +
          "." +
          ext;
        const image_params = {
          shop_id: store.getters["shops/currentShop"].id,
          base64_image: imgObj.src,
          file_name: file_name,
        };
        // ファイルアップロード
        const upload_result = await fileRepository
          .upload(image_params)
          .then((response) => {
            if (response.data) {
              if (response.data.status) {
                // 画像追加OK
                set(state.uploadImage, index, {
                  "path": response.data.file_name,
                  "type": image.type.split("/")[1].toUpperCase(),
                  "width": imgObj.width,
                  "height": imgObj.height,
                  "is_enable": true,
                });
              } else {
                // 画像追加NG
                alert(response.data.message);
              }
              return response.data.status;
            }
          })
          .catch((error) => {
            throw (
              "ERROR:tabImage.vue/save fileRepository.upload (" + error + ")"
            );
          });
        if (!upload_result) return false;
      }
      return true;
    };

    const createImage = async (image, index) => {
      const reader = new FileReader();
      // imageをreaderにDataURLとしてattachする
      reader.readAsDataURL(image);
      // readAdDataURLが完了したあと実行される処理
      reader.onload = () => {
        const imgObj = new Image();
        imgObj.src = reader.result;
        imgObj.onload = () => {
          // すぐストレージにアップロードする
          uploadServer(index, imgObj, image);
        };
      };
    };

    const onImageUploaded = async (e) => {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const type = files[0].type;
      if (
        type !== "image/jpeg" &&
        type !== "image/gif" &&
        type !== "image/webp" &&
        type !== "image/png"
      ) {
        alert(
          ".jpg、.gif、.png、.webpのいずれかのファイルのみ許可されています。[" +
            files[0].type +
            "]"
        );
        return;
      }
      // 画像を保持
      const index = e.target.getAttribute("upload_index");
      await createImage(files[0], index);
    };

    const getImageList = () => {
      return state.uploadImage;
    };

    const setImage = (img, index) => {
      set(state.uploadImage, index, img);
    };

    const init = async () => {
      state.uploadImage = props.image_list;
    };

    init();

    const onCopy = async ($event) => {
      ctx.emit("copy_click", $event);
    };

    return {
      dragOptions: {
        animation: 200,
        group: props.shop_site_id,
        disabled: false,
        ghostClass: "ghost",
      },
      setting,
      props,
      ...toRefs(state),
      onMonitor,
      onImageDelete,
      onImageUploaded,
      onCopy,
      getImageList,
      setImage,
      uploadServer,
    };
  },
};
</script>

<style scoped>
/** アイコンの間隔を調整 */
.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 4px;
}
.image_flame {
  overflow: auto;
}
.image_box {
  display: flex;
}
.image_card {
  margin-right: 0.25em;
  position: relative;
}
.image_drag_btn {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 4;
}
.image_info {
  position: absolute;
  bottom: 44px;
  right: 0px;
  z-index: 4;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}
.white_back {
  background-color: rgba(255, 255, 255, 0.5);
  height: 220px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
</style>
