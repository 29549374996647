var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('only-site-card',{attrs:{"com_data":_vm.com_data},scopedSlots:_vm._u([{key:"item_edit",fn:function(){return [_c('ValidationObserver',{ref:"observer"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("女の子からのメッセージ")]),_c('p',{staticClass:"red--text mini"},[_vm._v(" 未入力の場合、コメントタブの「"),_c('strong',[_vm._v("キャストコメント")]),_vm._v("」の内容で更新します。 ")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.girl_comments.text,"rules":_vm.items.girl_comments.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"girl_comments",attrs:{"name":"girl_comments","error-messages":errors,"success":valid,"label":_vm.items.girl_comments.text,"placeholder":"女の子からのメッセージ","rows":"6","outlined":"","counter":_vm.items.girl_comments.counter,"background-color":"white"},model:{value:(_vm.cast_prop.girl_comments),callback:function ($$v) {_vm.$set(_vm.cast_prop, "girl_comments", $$v)},expression:"cast_prop.girl_comments"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-8"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("お店からのメッセージ タイトル")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.title.text,"rules":_vm.items.title.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"title",attrs:{"name":"title","error-messages":errors,"success":valid,"label":_vm.items.title.text,"outlined":"","counter":_vm.items.title.counter,"background-color":"white"},model:{value:(_vm.cast_prop.title),callback:function ($$v) {_vm.$set(_vm.cast_prop, "title", $$v)},expression:"cast_prop.title"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("お店からのメッセージ")]),_c('p',{staticClass:"red--text mini"},[_vm._v(" 未入力の場合、コメントタブの「"),_c('strong',[_vm._v("お店コメント")]),_vm._v("」の内容で更新します。 ")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.comments.text,"rules":_vm.items.comments.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"comments",attrs:{"name":"comments","error-messages":errors,"success":valid,"label":_vm.items.comments.text,"placeholder":"お店からのメッセージ","rows":"6","outlined":"","counter":_vm.items.comments.counter,"background-color":"white"},model:{value:(_vm.cast_prop.comments),callback:function ($$v) {_vm.$set(_vm.cast_prop, "comments", $$v)},expression:"cast_prop.comments"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-8"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("可能オプション")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.options.text,"rules":_vm.items.options.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"options",attrs:{"name":"options","error-messages":errors,"success":valid,"label":_vm.items.options.text,"hint":"可能オプション(△は要確認)","persistent-hint":"","rows":"6","outlined":"","counter":_vm.items.options.counter,"background-color":"white"},model:{value:(_vm.cast_prop.options),callback:function ($$v) {_vm.$set(_vm.cast_prop, "options", $$v)},expression:"cast_prop.options"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-8"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("女の子ランキング"),_c('br'),_vm._v("優先タグ選択")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_vm._v(" 女の子ランキング優先タグ選択を"+_vm._s(_vm.items.p_genre.counter)+"個以内で選択してください。 "),_c('ValidationProvider',{attrs:{"name":_vm.items.p_genre.text,"rules":_vm.items.p_genre.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [(!valid)?_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.items.p_genre.counter)+"個までしか選択出来ません ")]):_vm._e(),_c('v-row',{staticClass:"mt-2 mb-2"},_vm._l((_vm.p_genre_type),function(p_genre){return _c('v-col',{key:p_genre.label,attrs:{"cols":"4","sm":"3","md":"2"}},[_c('v-checkbox',{ref:"p_genre",refInFor:true,staticClass:"mt-1",attrs:{"name":"p_genre","label":p_genre.label,"value":p_genre.value,"success":valid,"error":!valid,"hide-details":""},model:{value:(_vm.p_genre_selected),callback:function ($$v) {_vm.p_genre_selected=$$v},expression:"p_genre_selected"}})],1)}),1)]}}])})],1)],1),_c('v-divider',{staticClass:"mt-4 mb-8"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("ジャンル選択")]),_c('div',{staticClass:"item-req"},[_c('div',[_vm._v("必須項目")])])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_vm._v(" ジャンルを"+_vm._s(_vm.items.genre.counter)+"個以内で選択してください。 "),_c('div',{staticClass:"pa-1"},[_c('div',{staticClass:"pa-2 selected_genre"},[_c('div',{staticClass:"mb-1"},[_vm._v(" 選択中のジャンル※ドラッグで並び替えできます。上位3個は媒体にて大きなアイコンで表示されます ")]),_c('draggable',_vm._b({attrs:{"handle":".chip_label","force-fallback":true},model:{value:(_vm.genre_selected_label),callback:function ($$v) {_vm.genre_selected_label=$$v},expression:"genre_selected_label"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.genre_selected_label),function(value,key){return _c('v-chip',{key:key,staticClass:"chip_label mr-1 mb-1 white--text font-weight-bold success",staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(value))])}),1)],1)]),_c('ValidationProvider',{attrs:{"name":_vm.items.genre.text,"rules":_vm.items.genre.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [(!valid)?_c('div',{staticClass:"red--text"},[(_vm.genre_selected_label.length == 0)?_c('span',[_vm._v("必須入力です。1個以上、選択してください。")]):_vm._e(),(_vm.genre_selected_label.length > _vm.items.genre.counter)?_c('span',[_vm._v("最大"+_vm._s(_vm.items.genre.counter)+"個まで選択できます。")]):_vm._e()]):_vm._e(),_vm._l((_vm.genre_type),function(value,key){return _c('div',{key:key},[_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('div',[_vm._v(_vm._s(value.category))]),_c('v-row',{staticClass:"mt-2 mb-2"},_vm._l((value.items),function(genre){return _c('v-col',{key:genre.label,attrs:{"cols":"4","sm":"3","md":"2"}},[_c('v-checkbox',{ref:"genre",refInFor:true,staticClass:"mt-1",attrs:{"name":"genre","label":genre.label,"value":genre.value,"success":valid,"error":!valid,"hide-details":""},model:{value:(_vm.genre_selected),callback:function ($$v) {_vm.genre_selected=$$v},expression:"genre_selected"}})],1)}),1)],1)})]}}])})],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }