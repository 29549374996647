<template>
  <div>
    <div class="pa-2 cyan">
      <span class="text-subtitle-1 font-weight-bold white--text"
        >全媒体共通の設定</span
      >
    </div>
    <v-card tile elevation="0" class="item-card pa-2">
      <v-card-text class="pa-0">
        <div class="pb-2 mb-2">
          <span class=""
            >全媒体共通で使用するコメントを設定します。媒体別にコメントを設定したい場合は、「<strong>媒体個別項目</strong>」でコメントを設定してください。</span
          >
        </div>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2">
                <div class="font-weight-bold mb-2">お店コメント</div>
                <item-required
                  :item_rules="itemRules"
                  item_key="cast__basic__comment_by_shop"
                ></item-required>
              </v-col>
              <v-col cols="12" sm="10">
                <!-- ショップコメント タブバージョン -->
                <v-row>
                  <v-col col="12">
                    <div class="mt-1 mb-1">
                      <span class="font-weight-bold text-align-middle"
                        >テキスト用</span
                      >
                    </div>
                    <v-tabs
                      v-model="refCommentByShopTab"
                      show-arrows
                      height="32"
                      :hide-slider="true"
                    >
                      <v-tab
                        v-for="tabData in commentByShopData"
                        :key="'bt' + tabData.index"
                        active-class="blue lighten-5 font-weight-bold"
                      >
                        {{ tabData.name }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="refCommentByShopTab">
                      <v-tab-item
                        v-for="tabData in commentByShopData"
                        :key="'bi' + tabData.index"
                        :eager="true"
                        class="blue lighten-5 pt-2"
                      >
                        <div class="ma-3 mb-0">
                          <ValidationProvider
                            v-slot="{ errors, valid }"
                            :name="items.cast__basic__comment_by_shop.text"
                            :rules="'max:' + tabData.counter"
                          >
                            <v-textarea
                              v-model="tabData.value"
                              :name="tabData.el_name"
                              :rules="tabData.rules"
                              :counter="tabData.counter"
                              :error-messages="errors"
                              :success="valid"
                              :hint="
                                tabData.hint +
                                (itemRules.cast__basic__comment_by_shop.comment
                                  .length > 0
                                  ? '※' +
                                    itemRules.cast__basic__comment_by_shop
                                      .comment
                                  : '')
                              "
                              :label="items.cast__basic__comment_by_shop.text"
                              rows="8"
                              persistent-hint
                              outlined
                              background-color="white"
                            />
                          </ValidationProvider>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="12">
                    <div class="mt-1 mb-1">
                      <span class="font-weight-bold text-align-middle"
                        >HTML用&nbsp;</span
                      ><v-btn
                        text
                        color="primary"
                        class="font-weight-bold pa-1 d-inline"
                        @click="onHtmlClear('shop')"
                        >HTMLをクリア</v-btn
                      >
                    </div>
                    <div class="">
                      <ckeditor-component
                        ref="refCommentByShopCkeditor"
                        :data="commentByShopDataHTML"
                      ></ckeditor-component>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-8 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2">
                <div class="font-weight-bold mb-2">キャストコメント</div>
                <item-required
                  :item_rules="itemRules"
                  item_key="cast__basic__comment_by_cast"
                ></item-required>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col col="12">
                    <div class="mt-1 mb-1">
                      <span class="font-weight-bold text-align-middle"
                        >テキスト用</span
                      >
                    </div>
                    <v-tabs
                      v-model="refCommentByCastTab"
                      show-arrows
                      height="32"
                      :hide-slider="true"
                    >
                      <v-tab
                        v-for="tabData in commentByCastData"
                        :key="'bt' + tabData.index"
                        active-class="blue lighten-5 font-weight-bold"
                      >
                        {{ tabData.name }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="refCommentByCastTab">
                      <v-tab-item
                        v-for="tabData in commentByCastData"
                        :key="'bi' + tabData.index"
                        :eager="true"
                        class="blue lighten-5 pt-2"
                      >
                        <div class="ma-3 mb-0">
                          <ValidationProvider
                            v-slot="{ errors, valid }"
                            :name="items.cast__basic__comment_by_cast.text"
                            :rules="'max:' + tabData.counter"
                          >
                            <v-textarea
                              v-model="tabData.value"
                              :name="tabData.el_name"
                              :rules="tabData.rules"
                              :counter="tabData.counter"
                              :error-messages="errors"
                              :success="valid"
                              :hint="
                                tabData.hint +
                                (itemRules.cast__basic__comment_by_cast.comment
                                  .length > 0
                                  ? '※' +
                                    itemRules.cast__basic__comment_by_cast
                                      .comment
                                  : '')
                              "
                              :label="items.cast__basic__comment_by_cast.text"
                              rows="8"
                              persistent-hint
                              outlined
                              background-color="white"
                            />
                          </ValidationProvider>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="12">
                    <div class="mt-1 mb-1">
                      <span class="font-weight-bold text-align-middle"
                        >HTML用&nbsp;</span
                      ><v-btn
                        text
                        color="primary"
                        class="font-weight-bold pa-1 d-inline"
                        @click="onHtmlClear('cast')"
                        >HTMLをクリア</v-btn
                      >
                    </div>
                    <div class="">
                      <ckeditor-component
                        ref="refCommentByCastCkeditor"
                        :data="commentByCastDataHTML"
                      ></ckeditor-component>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col cols="6" sm="3">
              <general-button btn_type="info" btn_block @click-event="onSubmit"
                ><template v-slot:icon
                  ><v-icon left>mdi-content-save</v-icon></template
                >保存</general-button
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <general-button btn_type="gray" btn_block :btn_disabled="true"
                ><template v-slot:icon
                  ><v-icon left>mdi-chevron-left</v-icon></template
                >前へ</general-button
              >
            </v-col>
            <v-col cols="3">
              <general-button
                btn_type="info"
                btn_block
                :btn_disabled="cast_id == 0"
                @click-event="onForward"
                ><template v-slot:icon
                  >次へ<v-icon right>mdi-chevron-right</v-icon></template
                ></general-button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import itemRequired from "@/component/itemRequired.vue";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";
import selectItem from "@/common/selectItem.js";

export default defineComponent({
  components: {
    itemRequired,
  },
  props: ["cast_data", "site_item"],
  setup(props, ctx) {
    const CastsRepository = repositoryFactory.get("casts");
    const CastPropsRepository = repositoryFactory.get("castProps");

    const refCommentByShopTab = ref();
    const refCommentByShopCkeditor = ref(null);
    const refCommentByCastTab = ref();
    const refCommentByCastCkeditor = ref(null);

    const state = reactive({
      errorElement: null,
      // キャストデータ格納用(cast_propsテーブルのkeyと同一)
      cast_id: null,
      cast_name: null,
      cast_is_enable: false,
      cast_prop: {},
      // お店コメントタブ、CKEditor
      commentByShopData: [],
      comment_by_shop_tab_counter: [10000, 100, 200, 300, 400, 500, 1000, 1500],
      commentByShopDataHTML: "",
      // キャストコメントタブ、CKEditor
      commentByCastData: [],
      comment_by_cast_tab_counter: [10000, 100, 200, 300, 400, 500, 1000, 1500],
      commentByCastDataHTML: "",
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_id: null,
        cast_name: null,
        cast_is_enable: false,
        cast_prop: {},
      },
      // 入力項目のルール
      itemRules: {},
    });

    const onSubmit = async () => {
      // 親コンポーネントの保存処理に投げる
      ctx.emit("com_save");
    };

    // チェック処理
    const checkValid = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      state.errorElement = null;
      let isValid = await ctx.refs.observer.validate();

      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        const errorKey = utilFunc.getKeyByItemText(
          setting.cast_props_common_comment,
          item_name
        );
        state.errorElement = ctx.refs[errorKey].$el;
      } else {
        // isValidがtrueでも、コメント関連タブ(textarea)でエラーが出ている場合がある。
        // エラー表示のクラスが画面にないか調べる
        const el = document.querySelectorAll(".v-textarea.error--text");
        // エラー状態の部品あり
        if (el.length != 0) {
          const item_name = el[0]
            .querySelector("textarea")
            .getAttribute("name")
            .split("_")[0];
          const item_index = Number(
            el[0].querySelector("textarea").getAttribute("name").split("_")[1]
          );

          // エラーのあるタブを表示する
          if (item_name == "shopComment") {
            refCommentByShopTab.value = item_index;
            state.errorElement = document.querySelector(
              "#comment_by_shop_head"
            );
          } else if (item_name == "castComment") {
            refCommentByCastTab.value = item_index;
            state.errorElement = document.querySelector(
              "#comment_by_cast_head"
            );
          }
          isValid = false;
        }
      }
      // if (isValid) {
      //   // 同名チェック--同じ名前のキャストがいたらエラーにする
      //   const params = {
      //     id: state.cast_id == "new" ? 0 : state.cast_id,
      //     shop_id: store.getters["shops/currentShop"].id,
      //     name: state.cast_name,
      //   };
      //   const check_name = await CastsRepository.check_samename(params)
      //     .then((response) => {
      //       if (response.data) {
      //         return response.data.result;
      //       }
      //     })
      //     .catch((error) => {
      //       throw (
      //         "ERROR:tabBasic.vue/checkValid CastsRepository.check_samename (" +
      //         error +
      //         ")"
      //       );
      //     });
      //   if (!check_name) {
      //     alert("同じ名前のキャストは登録できません。");
      //     state.errorElement = ctx.refs["cast__basic__name"].$el;
      //     isValid = false;
      //   }
      // }

      return isValid;
    };

    const setErrorFocus = async () => {
      // タブ切り替え時にタイムラグが生じるため、0.3秒後にエラーの項目へスクロールさせる
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    // 保存処理
    const save = async () => {
      let isStoreReload = false;
      // // castsテーブルの保存
      // if (state.cast_id == 0) {
      //   // 新規
      //   const params = {
      //     id: state.cast_id,
      //     shop_id: store.getters["shops/currentShop"].id,
      //     name: state.cast_name,
      //     sort: 9999,
      //     is_enable: state.cast_is_enable,
      //     is_active: true,
      //   };
      //   const id = await CastsRepository.create(params)
      //     .then((response) => {
      //       if (response.data) {
      //         // データ追加OK
      //         ctx.refs.observer.reset(); // 入力エラー表示クリア
      //         return response.data.id;
      //       }
      //     })
      //     .catch((error) => {
      //       throw (
      //         "ERROR:tabBasic.vue/save CastsRepository.update (" + error + ")"
      //       );
      //     });
      //   state.cast_id = id;
      //   isStoreReload = true;
      // } else {
      //   // 更新前と比べて差分があれば更新処理
      //   if (
      //     state.keepData.cast_name != state.cast_name ||
      //     state.keepData.cast_is_enable != state.cast_is_enable
      //   ) {
      //     // 更新
      //     const params = {
      //       id: state.cast_id,
      //       shop_id: store.getters["shops/currentShop"].id,
      //       name: state.cast_name,
      //       is_enable: state.cast_is_enable,
      //     };
      //     await CastsRepository.update(params)
      //       .then((response) => {
      //         if (response.data) {
      //           // データ追加OK
      //         }
      //       })
      //       .catch((error) => {
      //         throw (
      //           "ERROR:tabBasic.vue/save CastsRepository.update (" + error + ")"
      //         );
      //       });
      //     isStoreReload = true;
      //   }
      //   ctx.refs.observer.reset(); // 入力エラー表示クリア
      // }

      // コメント関連のみ、別配列にデータが格納されているので、jsonにして、cast_propに格納する
      const tmpCommentByShopData = {};
      Object.keys(state.commentByShopData).forEach(function (key) {
        tmpCommentByShopData[
          "shopComment_" + state.commentByShopData[key].counter
        ] = state.commentByShopData[key].value;
      });
      tmpCommentByShopData["shopComment_html"] =
        refCommentByShopCkeditor.value.editorData;
      set(
        state.cast_prop,
        "cast__basic__comment_by_shop",
        JSON.stringify(tmpCommentByShopData)
      );

      const tmpCommentByCastData = {};
      Object.keys(state.commentByCastData).forEach(function (key) {
        tmpCommentByCastData[
          "castComment_" + state.commentByCastData[key].counter
        ] = state.commentByCastData[key].value;
      });
      tmpCommentByCastData["castComment_html"] =
        refCommentByCastCkeditor.value.editorData;
      set(
        state.cast_prop,
        "cast__basic__comment_by_cast",
        JSON.stringify(tmpCommentByCastData)
      );

      // 更新前と比べて差分があれば更新処理
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        // cast_propsテーブルの保存
        const prop_param = [];
        for (let key in state.cast_prop) {
          if (key == "cast__basic__name") continue; // "cast__basic__name"は使わない
          prop_param.push({
            cast_id: state.cast_id,
            key: key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:tabBasic.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
        isStoreReload = true;
      }

      ctx.emit("update_header", { name: state.cast_name + " 【編集】" });

      // // cast store 読み込み直す
      // if (isStoreReload) {
      //   await modelCast.setStore();
      // }

      return true;
    };

    // キャストIDを返す
    const getCastId = async () => {
      return state.cast_id;
    };

    const init = async () => {
      // cast_propにキーを設定
      for (let key in setting.cast_props_common_comment) {
        set(state.cast_prop, key, null);
      }

      // コメント関係 内容・初期化
      // お店コメント
      state.commentByShopData.slice(0);
      state.comment_by_shop_tab_counter.forEach((counter, index) => {
        let tabLabel = "";
        if (counter == 10000) {
          tabLabel = "基本";
        } else {
          tabLabel = counter + "文字";
        }
        state.commentByShopData.push({
          index: index,
          value: "",
          name: tabLabel,
          el_name: "shopComment_" + index,
          rules: [
            (v) => v.length <= counter || "最大" + counter + "文字までです",
          ],
          counter: counter,
          hint: counter + "文字以内で内容を入力してください",
        });
      });
      // キャストコメント
      state.commentByCastData.slice(0);
      state.comment_by_cast_tab_counter.forEach((counter, index) => {
        let tabLabel = "";
        if (counter == 10000) {
          tabLabel = "基本";
        } else {
          tabLabel = counter + "文字";
        }
        state.commentByCastData.push({
          index: index,
          value: "",
          name: tabLabel,
          el_name: "castComment_" + index,
          rules: [
            (v) => v.length <= counter || "最大" + counter + "文字までです",
          ],
          counter: counter,
          hint: counter + "文字以内で内容を入力してください",
        });
      });

      // 親コンポーネントからの値をセット
      state.cast_id = props.cast_data.cast_id;
      state.cast_name = props.cast_data.cast_name;
      state.cast_is_enable = props.cast_data.cast_is_enable;
      for (let key in state.cast_prop) {
        set(state.cast_prop, key, props.cast_data.cast_prop[key]);
      }

      // サイト別の入力項目ルールを表示する cast_props_common_comment
      Object.keys(setting.cast_props_common_comment).forEach(function (key) {
        let tmpData = {
          item_key: key,
          is_required: false,
          comment: "",
        };
        if (key == "cast__basic__name") {
          tmpData.is_required = true;
        }
        Object.keys(props.site_item).forEach(function (index) {
          if (props.site_item[index].name == key) {
            if (props.site_item[index].is_required) {
              tmpData.is_required = props.site_item[index].is_required;
            }
            if (
              (props.site_item[index].max_cnt != null &&
                Number(props.site_item[index].max_cnt) <
                  Number(setting.cast_props_common_comment[key].counter)) ||
              setting.cast_props_common_comment[key].counter == ""
            ) {
              tmpData.comment =
                tmpData.comment +
                props.site_item[index].site_name +
                "の最大文字数は" +
                props.site_item[index].max_cnt +
                "文字です。";
            }
          }
        });
        state.itemRules[key] = tmpData;
      });

      // コメント関係セット
      if (state.cast_prop.cast__basic__comment_by_shop) {
        const tmpCommentByShopData = JSON.parse(
          state.cast_prop.cast__basic__comment_by_shop
        );
        for (const [key, value] of Object.entries(tmpCommentByShopData)) {
          const keyItem = key.split("_");
          Object.keys(state.commentByShopData).forEach(function (key) {
            if (state.commentByShopData[key].counter == keyItem[1]) {
              set(state.commentByShopData[key], "value", value);
            }
            if ("html" == keyItem[1]) {
              state.commentByShopDataHTML = value;
              if (refCommentByShopCkeditor.value) {
                refCommentByShopCkeditor.value.editorData = value;
              }
            }
          });
        }
      }
      if (state.cast_prop.cast__basic__comment_by_cast) {
        const tmpCommentByCastData = JSON.parse(
          state.cast_prop.cast__basic__comment_by_cast
        );
        for (const [key, value] of Object.entries(tmpCommentByCastData)) {
          const keyItem = key.split("_");
          Object.keys(state.commentByCastData).forEach(function (key) {
            if (state.commentByCastData[key].counter == keyItem[1]) {
              set(state.commentByCastData[key], "value", value);
            }
            if ("html" == keyItem[1]) {
              state.commentByCastDataHTML = value;
              if (refCommentByCastCkeditor.value) {
                refCommentByCastCkeditor.value.editorData = value;
              }
            }
          });
        }
      }
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_name = state.cast_name;
      state.keepData.cast_is_enable = state.cast_is_enable;
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);
    };

    init();

    const onHtmlClear = (mode) => {
      let mode_label = "";
      if (mode == "shop") {
        mode_label = "お店コメント";
      } else {
        mode_label = "キャストコメント";
      }
      if (
        !window.confirm(
          mode_label + "のHTMLに入力された内容をクリアします。よろしいですか？"
        )
      ) {
        return;
      }
      if (mode == "shop") {
        state.commentByShopDataHTML = "";
        if (refCommentByShopCkeditor.value) {
          refCommentByShopCkeditor.value.editorData = "";
        }
      } else {
        state.commentByCastDataHTML = "";
        if (refCommentByCastCkeditor.value) {
          refCommentByCastCkeditor.value.editorData = "";
        }
      }
    };

    const onForward = () => {
      ctx.emit("tab_forward");
    };

    const items = setting.cast_props_common_comment;

    return {
      items,
      setting,
      selectItem,
      refCommentByShopTab,
      refCommentByShopCkeditor,
      refCommentByCastTab,
      refCommentByCastCkeditor,
      ...toRefs(state),
      onSubmit,
      checkValid,
      setErrorFocus,
      save,
      getCastId,
      onHtmlClear,
      onForward,
    };
  },
});
</script>

<style scoped>
.v-tab--active {
  background-color: #e8f5e9;
}
</style>
