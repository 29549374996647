<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2">
                <span class="font-weight-bold">お店からのひとこと</span>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>お店コメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.commentA.text"
                      :rules="items.commentA.rules"
                    >
                      <v-textarea
                        ref="commentA"
                        v-model="cast_prop.commentA"
                        name="commentA"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.commentA.text"
                        :placeholder="`※セラピストの身体的特徴に関する紹介文はお控えください（バストサイズやスリーサイズなども含む）
※健全なマッサージ店では考えられないような紹介文の場合、ご連絡なく編集もしくは削除させていただきます
※何度も問題となる文章を登録されている場合は、機能の利用停止、また悪質と判断した場合は広告掲載を停止させていただきますのでご注意ください
※当サイトで指定する規制ワード、機種依存文字、HTMLタグは使用できません`"
                        rows="6"
                        outlined
                        persistent-hint
                        hint="全角200文字以内でかつ15行以内でご入力ください"
                        :counter="items.commentA.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">趣味</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.hobby.text"
                      :rules="items.hobby.rules"
                    >
                      <v-text-field
                        ref="hobby"
                        v-model="cast_prop.hobby"
                        name="hobby"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.hobby.text"
                        placeholder="例）映画鑑賞、Youtube、旅行"
                        persistent-hint
                        outlined
                        hint="全角50文字以内でご入力ください"
                        :counter="items.hobby.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">入店日</span></v-col
              >
              <v-col cols="6" sm="3">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.enter.text"
                      :rules="items.enter.rules"
                    >
                      <v-text-field
                        ref="enter"
                        v-model="cast_prop.enter"
                        name="enter"
                        type="date"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.enter.text"
                        :disabled="enterDisableFlg"
                        persistent-hint
                        outlined
                        hint="※本日より７日後まで設定可能です。一度、設定すると変更できません。"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><div class="font-weight-bold">セラピストの特徴</div>
                <div class="item-req">
                  <div>必須項目</div>
                </div>
              </v-col>
              <v-col cols="12" sm="10">
                セラピストの特徴を{{
                  items.feature.counter
                }}個以内で選択してください。
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.feature.text"
                  :rules="items.feature.rules"
                >
                  <div v-if="!valid" class="red--text">
                    <span v-if="feature_selected.length == 0"
                      >必須入力です。1個以上、6個以内で選択してください。</span
                    >
                    <span v-if="feature_selected.length > items.feature.counter"
                      >最大{{ items.feature.counter }}個まで選択できます。</span
                    >
                  </div>
                  <v-row class="mt-2 mb-2">
                    <v-col
                      v-for="feature in feature_type"
                      :key="feature.label"
                      cols="4"
                      sm="3"
                      md="2"
                    >
                      <v-checkbox
                        class="mt-1"
                        ref="feature"
                        v-model="feature_selected"
                        name="feature"
                        :label="feature.label"
                        :value="feature.value"
                        :success="valid"
                        :error="!valid"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><div class="font-weight-bold">マッサージ経験</div>
                <div class="item-req">
                  <div>必須項目</div>
                </div>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      ref="exp"
                      v-model="cast_prop.exp"
                      name="exp"
                      :items="exp_type"
                      :no-data-text="items.exp.text"
                      success
                      persistent-hint
                      outlined
                      hint="※虚偽がないよう設定をお願いいたします。各未経験項目をご選択いただいた場合でも、入店日が未入力の場合や在籍期間によっては初心者として反映されません"
                      background-color="white"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><div class="font-weight-bold">出勤時間</div>
                <div class="item-req">
                  <div>必須項目</div>
                </div>
              </v-col>
              <v-col cols="12" sm="10">
                セラピストの基本的な出勤時間です。スケジュール管理で日毎に変更が可能です
                <v-row class="mt-2 mb-2">
                  <v-col cols="4" sm="3" md="2">
                    <v-select
                      ref="defStartA"
                      v-model="cast_prop.defStartA"
                      name="defStartA"
                      :items="hour_type"
                      suffix="時"
                      success
                      outlined
                      hide-details
                      background-color="white"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" sm="3" md="2">
                    <v-select
                      ref="defStartB"
                      v-model="cast_prop.defStartB"
                      name="defStartB"
                      :items="minute_type"
                      suffix="分"
                      success
                      outlined
                      hide-details
                      background-color="white"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" md="1">から</v-col>
                  <v-col cols="4" sm="3" md="2">
                    <v-select
                      ref="defEndA"
                      v-model="cast_prop.defEndA"
                      name="defEndA"
                      :items="hour_type"
                      suffix="時"
                      success
                      outlined
                      hide-details
                      background-color="white"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" sm="3" md="2">
                    <v-select
                      ref="defEndB"
                      v-model="cast_prop.defEndB"
                      name="defEndB"
                      :items="minute_type"
                      suffix="分"
                      success
                      outlined
                      hide-details
                      background-color="white"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const state = reactive({
      errorElement: null,
      cast_prop: {},
      feature_selected: [],
      feature_type: {
        "feature-id-2": { label: "有資格者", value: "2" },
        "feature-id-5": { label: "女性エステ経験", value: "5" },
        "feature-id-22": { label: "丁寧な施術", value: "22" },
        "feature-id-23": { label: "力強い施術", value: "23" },
        "feature-id-6": { label: "社交的", value: "6" },
        "feature-id-9": { label: "話し上手", value: "9" },
        "feature-id-27": { label: "聞き上手", value: "27" },
        "feature-id-10": { label: "面白い", value: "10" },
        "feature-id-16": { label: "明るい", value: "16" },
        "feature-id-11": { label: "天然", value: "11" },
        "feature-id-20": { label: "おとなしい", value: "20" },
        "feature-id-13": { label: "いつも笑顔", value: "13" },
        "feature-id-19": { label: "クール", value: "19" },
        "feature-id-21": { label: "真面目", value: "21" },
        "feature-id-18": { label: "照れ屋", value: "18" },
        "feature-id-8": { label: "癒やし系", value: "8" },
        "feature-id-26": { label: "好奇心旺盛", value: "26" },
        "feature-id-14": { label: "インドア", value: "14" },
        "feature-id-15": { label: "アウトドア", value: "15" },
        "feature-id-28": { label: "レア出勤", value: "28" },
      },
      exp_type: [
        { value: "1", text: "経験あり" },
        {
          value: "4",
          text: "業界未経験 - 掲載カテゴリ（プライベートサロン等）での実務経験なし",
        },
        {
          value: "5",
          text: "完全未経験 - マッサージに関する実務経験が一切なし",
        },
      ],
      hour_type: [
        { value: "08", text: "08" },
        { value: "09", text: "09" },
        { value: "10", text: "10" },
        { value: "11", text: "11" },
        { value: "12", text: "12" },
        { value: "13", text: "13" },
        { value: "14", text: "14" },
        { value: "15", text: "15" },
        { value: "16", text: "16" },
        { value: "17", text: "17" },
        { value: "18", text: "18" },
        { value: "19", text: "19" },
        { value: "20", text: "20" },
        { value: "21", text: "21" },
        { value: "22", text: "22" },
        { value: "23", text: "23" },
        { value: "00", text: "翌0" },
        { value: "01", text: "翌1" },
        { value: "02", text: "翌2" },
        { value: "03", text: "翌3" },
        { value: "04", text: "翌4" },
        { value: "05", text: "翌5" },
        { value: "06", text: "翌6" },
        { value: "07", text: "翌7" },
      ],
      minute_type: [
        { value: "00", text: "00" },
        { value: "10", text: "10" },
        { value: "20", text: "20" },
        { value: "30", text: "30" },
        { value: "40", text: "40" },
        { value: "50", text: "50" },
      ],
      enterDisableFlg: false,
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
      items: {
        "commentA": {
          text: "お店からのひとこと",
          rules: "max:200",
          counter: "200",
        },
        "hobby": {
          text: "趣味",
          rules: "max:50",
          counter: "50",
        },
        "enter": {
          text: "入店日",
          rules: {
            max_day_from_today: { length: 7, disabled: false },
          },
          counter: "10",
        },
        "feature": {
          text: "特徴",
          rules: "required|check_max:6",
          counter: "6",
        },
        "exp": {
          text: "マッサージ経験",
          rules: "",
          counter: "",
        },
        "出勤時間": {
          text: "マッサージ経験",
          rules: "",
          counter: "",
        },
      },
    });

    const init = async () => {
      // cast_propにキーを設定
      for (let key in state.items) {
        set(state.cast_prop, key, null);
      }
      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        set(state.cast_prop, key, props.com_data.cast_prop[key]);
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);

      // セラピストの特徴をjsonから配列に変換しておく
      if (state.cast_prop.feature) {
        state.feature_selected = JSON.parse(state.cast_prop.feature);
      }
      // マッサージ経験初期値
      if (!state.cast_prop.exp) {
        state.cast_prop.exp = "1";
      }
      // 出勤時間 開始時
      if (!state.cast_prop.defStartA) {
        state.cast_prop.defStartA = "10";
      }
      // 出勤時間 開始分
      if (!state.cast_prop.defStartB) {
        state.cast_prop.defStartB = "00";
      }
      // 出勤時間 終了時
      if (!state.cast_prop.defEndA) {
        state.cast_prop.defEndA = "05";
      }
      // 出勤時間 終了分
      if (!state.cast_prop.defEndB) {
        state.cast_prop.defEndB = "00";
      }

      // 入店日の編集フラグ 本日から7日後まで設定可能で、一度設定すると変更できないため、すでに入力されている場合は入力不可にする
      if (state.cast_prop.enter) {
        // 設定されている > 入力不可にして、バリデーションの条件を変更
        state.enterDisableFlg = true;
        state.items.enter.rules.max_day_from_today.disabled = true;
      }
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      state.errorElement = null;
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        const errorKey = utilFunc.getKeyByItemText(state.items, item_name);
        if (errorKey == "feature") {
          state.errorElement = ctx.refs[errorKey][0].$el;
        } else {
          state.errorElement = ctx.refs[errorKey].$el;
        }
      }
      return isValid;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    // 保存処理
    const save = async () => {
      // 更新前と比べて差分があれば更新処理
      for (let key in state.cast_prop) {
        if (key == "feature") {
          set(
            state.cast_prop,
            key,
            state.feature_selected.length == 0
              ? ""
              : JSON.stringify(state.feature_selected)
          );
        }
      }
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          prop_param.push({
            cast_id: props.com_data.cast_id,
            key: props.com_data.prefix + "__" + key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:estama.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    return {
      props,
      setting,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
    };
  },
});
</script>

<style scoped></style>
