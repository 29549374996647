<template>
  <div>
    <app-bar
      ref="refAppBar"
      :key="refreshKey"
      :cast_id="cast_data.cast_id"
      @on_back="onBack"
      @on_save="comSave"
      @on_sync="onSync"
    ></app-bar>

    <!-- キャストヘッダー -->
    <div
      id="cast_header_bar"
      class="pa-2 blue--text blue lighten-5 d-flex align-center"
    >
      <div class="mr-2">
        <v-img
          width="60"
          height="80"
          :src="setting.cdn + cast_header.image"
          v-if="cast_header.image"
        ></v-img>
        <v-img
          width="60"
          height="80"
          v-else
          src="@/assets/images/no_image.jpg"
        ></v-img>
      </div>
      <div class="mr-2">{{ cast_header.name }}</div>
    </div>

    <!-- 編集タブ -->
    <v-tabs v-model="refTabs">
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="tab in tabItems"
        :key="tab.mode"
        class="font-weight-bold"
        :disabled="tab.disabled"
        >{{ tab.name }}</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="refTabs" touchless>
      <v-tab-item
        v-for="tab in tabItems"
        :key="tab.mode"
        :disabled="tab.disabled"
        :eager="true"
      >
        <component
          v-bind:is="tab.component"
          ref="refTabItemComponent"
          :key="refreshKey"
          :cast_data="tab.cast_data"
          :cast_sync_site_update_ids="castSyncSiteUpdateIds"
          :site_setting="siteContentList"
          :site_item="siteItemList"
          @com_save="comSave"
          @update_header="updateHeader"
          @tab_back="tabBack"
          @tab_forward="tabForward"
        ></component>
      </v-tab-item>
    </v-tabs-items>

    <!-- 一括更新ダイアログ -->
    <v-dialog
      v-model="castSyncDialog"
      scrollable
      persistent
      width="100%"
      max-width="600px"
    >
      <v-card :disabled="castSyncDialogDisabled">
        <v-card-title>
          <span class="text-h5">キャスト情報を更新</span>
        </v-card-title>
        <v-card-text>
          <div class="text-subtitle-2 pa-1 orange--text orange lighten-5 mb-2">
            「<span class="red--text">更新失敗</span
            >」と表示された場合、エラー内容を<router-link to="/result-log"
              >更新結果履歴</router-link
            >より確認してください。
          </div>
          <v-subheader class="pl-0">
            キャスト情報の更新処理を行います。更新したい媒体にチェックして下さい。
          </v-subheader>
          <h3>媒体を選択</h3>
          <v-container>
            <v-row>
              <v-col cols="12">
                <!-- テーブル -->
                <v-data-table
                  :headers="castSyncHeaders"
                  :items="castSyncSiteList"
                  :mobile-breakpoint="0"
                  item-key="uk"
                  show-select
                  :single-select="false"
                  v-model="castSyncSelected"
                  :footer-props="{ 'items-per-page-options': [-1] }"
                  hide-default-footer
                  @click:row="rowClick"
                >
                  <template v-slot:no-data>
                    表示するデータがありません
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.status="{ item }">
                    {{ item.status }}
                    &nbsp;
                    <v-progress-linear
                      v-if="item.status == '更新中'"
                      indeterminate
                      color="primary"
                    ></v-progress-linear>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button btn_color="gray" btn_block @click-event="onBack"
                  ><template v-slot:icon
                    ><v-icon left>mdi-arrow-left-circle</v-icon></template
                  >一覧に戻る</general-button
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelCastSyncDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmitCastSyncDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-sync</v-icon></template
                  >一括更新</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import { useRouter } from "@/utils";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";

import appBar from "./appBar.vue";
import tabBasicComponent from "./component/tabBasic.vue";
import tabImageComponent from "./component/tabImage.vue";
import tabOnlySiteComponent from "./component/tabOnlySite.vue";
import tabQaSiteComponent from "./component/tabQaSite.vue";
import tabCommentComponent from "./component/tabComment.vue";

export default defineComponent({
  components: {
    appBar,
  },
  props: ["cast_id"],
  setup(props, ctx) {
    const castsRepository = repositoryFactory.get("casts");
    const siteContentsRepository = repositoryFactory.get("siteContents");
    const siteItemsRepository = repositoryFactory.get("siteItems");
    const SynchroRepository = repositoryFactory.get("synchro");
    const ShopSitesRepository = repositoryFactory.get("shopSites");

    const { router } = useRouter();
    const refAppBar = ref();
    const refTabItemComponent = ref();
    const refTabs = ref();
    const refreshKey = ref(0);

    const state = reactive({
      cast_header: {
        name: null,
        image: null,
      },
      cast_data: {
        cast_id: null,
        cast_name: null,
        cast_is_enable: false,
        cast_image: null,
        cast_prop: {}, // setting.cast_props_commonを見てキーを設定
      },
      tabItems: [],
      siteContentList: [],
      siteItemList: [],
      // 一括更新ボタン関連
      castSyncDialog: false,
      castSyncDialogDisabled: false,
      castSyncSiteList: [],
      castSyncSelected: [],
      castSyncHeaders: [
        {
          text: "サイト名",
          value: "site_name",
          sortable: false,
        },
        {
          text: "ステータス",
          value: "status",
          sortable: false,
        },
      ],
      // キャストが更新できる媒体のID
      castSyncSiteUpdateIds: [],
    });

    const tabs = [
      {
        name: "　基本項目　",
        component: tabBasicComponent,
        disabled: false,
      },
      {
        name: "　コメント　",
        component: tabCommentComponent,
        disabled: false,
      },
      {
        name: "媒体個別項目",
        component: tabOnlySiteComponent,
        disabled: false,
      },
      {
        name: "Ｑ＆Ａ項目",
        component: tabQaSiteComponent,
        disabled: false,
      },
      {
        name: "キャスト画像",
        component: tabImageComponent,
        disabled: false,
      },
    ];

    // キャストデータを読み込む
    const readCastData = async (cast_id) => {
      if (isNaN(cast_id)) return false;

      // キャストに関する全データを読み込む
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      params.append("cast_id", cast_id);
      const result = await castsRepository
        .get_shopid(params)
        .then((response) => {
          if (response.data) {
            set(state.cast_data, "cast_id", cast_id);
            set(state.cast_data, "cast_name", response.data.name);
            set(state.cast_data, "cast_is_enable", response.data.is_enable);
            set(state.cast_data, "cast_image", response.data.image);
            Object.keys(response.data.prop).forEach(function (key) {
              if (!response.data.prop[key].key.indexOf("cast__")) {
                set(
                  state.cast_data.cast_prop,
                  response.data.prop[key].key,
                  response.data.prop[key].value
                );
              }
            });
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          // throw (
          //   "ERROR:castEdit/index.vue/readCastData CastsRepository.get_shopid (" +
          //   error +
          //   ")"
          // );
          // 404表示
          return false;
        });
      return result;
    };

    const getSiteSetting = (site_id) => {
      return state.siteContentList.find((v) => v.site_id === site_id);
    };

    const init = async () => {
      await store.dispatch("loadingIcon/showIcon");

      // cast_propにキーを設定
      for (let key in setting.cast_props_common) {
        set(state.cast_data.cast_prop, key, null);
      }

      const params = {
        shop_id: store.getters["shops/currentShop"].id,
      };

      // site_itemsから基本項目の入力ルールを取得する
      await siteItemsRepository
        .list_shop(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.siteItemList.push({
                site_id: response.data[key].site_id,
                site_name: response.data[key].site_name,
                name: response.data[key].name,
                is_required: response.data[key].is_required,
                max_cnt: response.data[key].max_cnt,
                min_cnt: response.data[key].min_cnt,
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:castEdit/index.vue/init siteItemsRepository.list_shop (" +
            error +
            ")"
          );
        });

      // site_contentsから媒体のキャスト設定情報を取得する
      await siteContentsRepository
        .list_shop(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              if (response.data[key].content_id == setting.content_id_cast) {
                state.siteContentList.push({
                  site_id: response.data[key].site_id,
                  title_limit: response.data[key].title_limit, // キャスト名の最大文字数
                  body_limit: response.data[key].body_limit, // 1キャストに設定できる画像枚数
                });
                console.log("title_limit", response.data[key].title_limit);
              }
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:castEdit/index.vue/init siteContentsRepository.list_shop (" +
            error +
            ")"
          );
        });

      state.castSyncSiteUpdateIds.splice(0);
      const cu_params = new URLSearchParams();
      cu_params.append("shop_id", store.getters["shops/currentShop"].id);
      await ShopSitesRepository.list_getcast_update(cu_params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.castSyncSiteUpdateIds.push(response.data[key].id); // shop_site_id
            });
          }
        })
        .catch((error) => {
          store.dispatch("loadingIcon/hideIcon");
          throw "ERROR:データ取得エラー (" + error + ")";
        });
      console.log("castSyncSiteUpdateIds", state.castSyncSiteUpdateIds);

      if (props.cast_id == "new") {
        // 新規作成
        state.cast_header.name = "【キャスト新規登録】";
        state.cast_header.image = null;

        set(state.cast_data, "cast_id", 0);
        set(state.cast_data, "cast_name", null);
        set(state.cast_data, "cast_is_enable", true);
        set(state.cast_data, "cast_image", null);
      } else {
        // 更新
        // キャストに関する全データを読み込む
        const result = await readCastData(props.cast_id);

        // 該当IDがない場合は404
        if (!result) {
          // キャスト登録なし(戻る)
          await store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
          router.push({ name: "error-404" });
          return;
        }
        // 画面ヘッダー設定
        state.cast_header.name = state.cast_data.cast_name + " 【編集】";
        state.cast_header.image = state.cast_data.cast_image;
      }

      // 各種タブに読み込んだキャストデータを紐付け
      await setDataTabs();
      for (let key = 0; key < tabs.length; key++) {
        state.tabItems.push(tabs[key]);
      }

      await store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    const setDataTabs = async () => {
      for (let index = 0; index < tabs.length; index++) {
        tabs[index].cast_data = state.cast_data;
        if (index != 0 && state.cast_data.cast_id == 0) {
          tabs[index].disabled = true;
        }
      }
    };

    // キャスト一覧に戻る
    const onBack = async () => {
      router.push({ name: "cast" });
    };

    // 保存処理
    const comSave = async (isSync = false) => {
      // 各タブのエラーチェック
      const max_index = refTabItemComponent.value.length;
      const tabCom = refTabItemComponent.value;
      let result = false;
      for (let index = 0; index < max_index; index++) {
        if (!tabs[index].disabled) {
          result = await tabCom[index].checkValid();
          if (!result) {
            // エラーのあるタブ、項目に飛ばす
            tabCom[index].setErrorFocus();
            refTabs.value = index;
            return false;
          }
        }
      }

      // 保存処理
      if (isSync) {
        if (
          !window.confirm(
            "キャスト情報を保存し、その後、各媒体に更新します。よろしいですか？"
          )
        ) {
          return false;
        }
      } else {
        if (!window.confirm("キャスト情報を保存します。よろしいですか？")) {
          return false;
        }
      }

      store.dispatch("loadingIcon/showIcon");

      for (let index = 0; index < max_index; index++) {
        result = await tabCom[index].save();

        // 新規の時、キャストデータを再読み込みし、タブのdisabledをすべてfalseにする。そして、次のタブに移って抜ける。
        if (index == 0 && state.cast_data.cast_id == 0) {
          const cast_id = await tabCom[index].getCastId();
          // 画面を再描画
          await readCastData(cast_id);
          refreshKey.value++;
          // タブのdisabledを解除
          Object.keys(state.tabItems).forEach(function (key) {
            state.tabItems[key].disabled = false;
          });
          // スクロールを一番上に戻しておく
          document.querySelector("#cast_header_bar").scrollIntoView({
            block: "center",
          });
          // 隣のタブに移動
          refTabs.value = index + 1;

          store.dispatch("loadingIcon/hideIcon");
          return true;
        }
      }

      // キャスト情報のlast_updateを更新する
      let params = new URLSearchParams();
      params = {
        id: state.cast_data.cast_id,
        shop_id: store.getters["shops/currentShop"].id,
      };
      let cast_update_result = false;
      // キャストを更新する
      await castsRepository
        .update_last_update(params)
        .then((response) => {
          if (response.data) {
            if (response.data.status) {
              return true;
            }
          }
        })
        .catch((error) => {
          throw (
            "ERROR:castEdit/index.vue/onSubmitCastSyncDialog castsRepository.update (" +
            error +
            ")"
          );
        });

      // 画面を再描画
      await readCastData(state.cast_data.cast_id);
      refreshKey.value++;

      store.dispatch("loadingIcon/hideIcon");
      return true;
    };

    // ヘッダー部分を更新
    const updateHeader = async (header_data) => {
      if ("name" in header_data)
        set(state.cast_header, "name", header_data.name);
      if ("image" in header_data)
        set(state.cast_header, "image", header_data.image);
    };

    const tabBack = () => {
      // スクロールを一番上に戻しておく
      document.querySelector("#cast_header_bar").scrollIntoView({
        block: "center",
      });
      // 隣のタブに移動
      refTabs.value = refTabs.value - 1;
    };

    const tabForward = () => {
      // スクロールを一番上に戻しておく
      document.querySelector("#cast_header_bar").scrollIntoView({
        block: "center",
      });
      // 隣のタブに移動
      refTabs.value = refTabs.value + 1;
    };

    const castSyncButton = async () => {
      // 更新サイト一覧取得
      state.castSyncSiteList.splice(0);
      state.castSyncSelected.splice(0);
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await ShopSitesRepository.list_getcast_update(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              const data = {
                uk: response.data[key].id, // shop_site_id
                site_content_id: response.data[key].site_content_id,
                site_name:
                  response.data[key].sites_name +
                  (JSON.parse(response.data[key].config).memo != ""
                    ? " [ " + JSON.parse(response.data[key].config).memo + " ] "
                    : ""),
                status: null,
              };
              state.castSyncSiteList.push(data);
              state.castSyncSelected.push(data);
            });
          }
        })
        .catch((error) => {
          throw "ERROR:データ取得エラー (" + error + ")";
        });

      state.castSyncDialog = true;
    };

    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    const cancelCastSyncDialog = async () => {
      state.castSyncDialog = false;
    };

    const onSubmitCastSyncDialog = async () => {
      // ここに更新処理
      if (
        !window.confirm(
          "選択したコンテンツを更新を開始します。よろしいですか？"
        )
      ) {
        return false;
      }
      // ラベル表示クリア
      for (let i = 0; i < state.castSyncSiteList.length; i++) {
        set(state.castSyncSiteList[i], "status", "");
      }
      // 順次更新処理を行う
      state.castSyncDialogDisabled = true;
      store.dispatch("loadingIcon/showIcon");

      // ラベル表示
      for (let i = 0; i < state.castSyncSelected.length; i++) {
        set(state.castSyncSelected[i], "status", "処理待ち");
      }
      // 順次処理する
      for (let i = 0; i < state.castSyncSelected.length; i++) {
        set(state.castSyncSelected[i], "status", "更新中");

        // 更新処理
        let params = new URLSearchParams();
        params = {
          shop_id: store.getters["shops/currentShop"]["id"],
          shop_site_id: state.castSyncSelected[i].uk,
          cast_id: state.cast_data.cast_id,
        };
        let result = false;

        // キャストを更新する
        result = await SynchroRepository.sync_cast(params)
          .then((response) => {
            if (response.data) {
              if (response.data.status) {
                return true;
              }
            } else {
              alert(response.data.message);
              return false;
            }
          })
          .catch((error) => {
            throw (
              "ERROR:castEdit/index.vue/onSubmitCastSyncDialog SynchroRepository.sync_cast (" +
              error +
              ")"
            );
          });

        if (result) {
          set(state.castSyncSelected[i], "status", "更新完了");
        } else {
          set(state.castSyncSelected[i], "status", "更新失敗");
        }
      }

      store.dispatch("loadingIcon/hideIcon");
      state.castSyncDialogDisabled = false;

      alert("更新処理が完了しました");
    };

    // キャストを更新
    const onSync = async () => {
      // 保存処理
      const result = await comSave(true);

      // 更新処理
      if (result) {
        await castSyncButton();
      }
    };

    return {
      props,
      setting,
      utilDate,
      refAppBar,
      refTabItemComponent,
      refTabs,
      refreshKey,
      ...toRefs(state),
      onBack,
      comSave,
      onSync,
      updateHeader,
      getSiteSetting,
      tabBack,
      tabForward,
      castSyncButton,
      rowClick,
      cancelCastSyncDialog,
      onSubmitCastSyncDialog,
    };
  },
});
</script>

<style scoped>
.header_col {
  display: inline-block;
  height: 80px;
}
.shop_site_span_fixed {
  font-size: 10.5px;
  background-color: #fafafa;
  word-break: break-all;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select_item {
  width: 4em;
}
.time_zone_box {
  display: inline-block;
}
</style>
