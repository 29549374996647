<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">女の子へ質問</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row v-for="i in 12" :key="i" style="margin-bottom: 2em">
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items[`questions_` + i].text"
                      :rules="items[`questions_` + i].rules"
                    >
                      <v-text-field
                        ref="refQuestion"
                        v-model="cast_prop[items[`questions_` + i].item_id]"
                        :name="items[`questions_` + i].item_id"
                        :error-messages="errors"
                        :success="valid"
                        :label="items[`questions_` + i].text"
                        outlined
                        :counter="items[`questions_` + i].counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items[`answers_` + i].text"
                      :rules="items[`answers_` + i].rules"
                    >
                      <v-textarea
                        ref="refAanswer"
                        v-model="cast_prop[items[`answers_` + i].item_id]"
                        :name="items[`answers_` + i].item_id"
                        :counter="items[`answers_` + i].counter"
                        :error-messages="errors"
                        :success="valid"
                        :label="items[`answers_` + i].text"
                        rows="4"
                        persistent-hint
                        background-color="white"
                        outlined
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const refQuestion = ref();
    const refAanswer = ref();
    const state = reactive({
      errorElement: null,
      items: {},
      cast_prop: {},
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
    });

    const init = async () => {
      // cast_propにキーを設定
      state.items = {};
      for (let i = 1; i <= 12; i++) {
        state.items["questions_" + i] = {
          item_id: "questions_" + i,
          text: "段落" + i + "(題名)",
          rules: "max:50",
          counter: "50",
        };
        state.items["answers_" + i] = {
          item_id: "answers_" + i,
          text: "段落" + i + "(内容)",
          rules: "max:200",
          counter: "200",
        };
      }
      for (let key in state.items) {
        set(state.cast_prop, key, null);
      }
      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        set(state.cast_prop, key, props.com_data.cast_prop[key]);
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      state.errorElement = null;
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        let errorKey = null;
        errorKey = utilFunc.getKeyByItemLabel(refQuestion.value, item_name);
        if (!errorKey) {
          errorKey = utilFunc.getKeyByItemLabel(refAanswer.value, item_name);
        }
        state.errorElement = refQuestion.value[errorKey].$el;
      }
      return isValid;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    // 保存処理
    const save = async () => {
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          prop_param.push({
            cast_id: props.com_data.cast_id,
            key: props.com_data.prefix + "__" + key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:estama.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    return {
      props,
      setting,
      refQuestion,
      refAanswer,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
    };
  },
});
</script>

<style scoped></style>
