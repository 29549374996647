<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2">
                <span class="font-weight-bold">お店からのひとこと</span>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>お店のコメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.shop_message.text"
                      :rules="items.shop_message.rules"
                    >
                      <v-textarea
                        ref="shop_message"
                        v-model="cast_prop.shop_message"
                        name="shop_message"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.shop_message.text"
                        rows="8"
                        outlined
                        :counter="items.shop_message.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">指名料金設定</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="6" md="3">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.nominate_fee.text"
                      :rules="items.nominate_fee.rules"
                    >
                      <v-text-field
                        ref="nominate_fee"
                        v-model="cast_prop.nominate_fee"
                        name="nominate_fee"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.nominate_fee.text"
                        persistent-hint
                        outlined
                        suffix="円"
                        :counter="items.nominate_fee.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="6" md="3">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.nominate_fee_status.text"
                      :rules="items.nominate_fee_status.rules"
                    >
                      <v-checkbox
                        class="mt-1"
                        ref="nominate_fee_status"
                        v-model="nominate_fee_status_selected"
                        name="nominate_fee_status"
                        :label="items.nominate_fee_status.text"
                        value="1"
                        :success="valid"
                        :error="!valid"
                        hide-details
                      ></v-checkbox>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">入店日</span></v-col
              >
              <v-col cols="6" sm="3">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      ref="join_date"
                      v-model="cast_prop.join_date"
                      name="join_date"
                      type="date"
                      :success="true"
                      :label="items.join_date.text"
                      :disabled="joinDateDisableFlg"
                      outlined
                      persistent-hint
                      hint="※入店日より3ヶ月間は新人アイコンが表示されます。入店日設定後の日付変更はできませんのでご注意ください。"
                      background-color="white"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><div class="font-weight-bold">マッサージ経験</div>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="3">
                    <v-select
                      ref="experience"
                      v-model="cast_prop.experience"
                      name="experience"
                      :items="experience_type"
                      no-data-text="--"
                      :label="items.experience.text"
                      success
                      outlined
                      background-color="white"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><div class="font-weight-bold">タイプ</div>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="3">
                    <v-select
                      ref="type"
                      v-model="cast_prop.type"
                      name="type"
                      :items="type_type"
                      no-data-text="--"
                      :label="items.type.text"
                      success
                      outlined
                      background-color="white"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><div class="font-weight-bold">特徴</div>
              </v-col>
              <v-col cols="12" sm="10">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.therapist_features.text"
                  :rules="items.therapist_features.rules"
                >
                  <v-row class="mt-2 mb-2">
                    <v-col
                      v-for="therapist_features in therapist_features_type"
                      :key="therapist_features.label"
                      cols="4"
                      sm="3"
                      md="2"
                    >
                      <v-checkbox
                        class="mt-1"
                        ref="therapist_features"
                        v-model="therapist_features_selected"
                        name="therapist_features"
                        :label="therapist_features.label"
                        :value="therapist_features.value"
                        :success="valid"
                        :error="!valid"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">得意な施術</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.treatment.text"
                      :rules="items.treatment.rules"
                    >
                      <v-text-field
                        ref="treatment"
                        v-model="cast_prop.treatment"
                        name="treatment"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.treatment.text"
                        placeholder="例）指圧マッサージ、オイルマッサージ"
                        outlined
                        :counter="items.treatment.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">趣味・マイブーム</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.hobby.text"
                      :rules="items.hobby.rules"
                    >
                      <v-text-field
                        ref="hobby"
                        v-model="cast_prop.hobby"
                        name="hobby"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.hobby.text"
                        placeholder="例）買い物、カフェ巡り、Youtube"
                        outlined
                        :counter="items.hobby.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">公式Xアカウント</span></v-col
              >
              <v-col cols="12" sm="10">
                <span>※該当するセラピストのXアカウントを登録してください。</span
                ><br />
                <span
                  >※アカウント名のみ入力してください。'@'(アットマーク)は必要ありません。</span
                >
                <v-row class="mt-2">
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.twitter_id.text"
                      :rules="items.twitter_id.rules"
                    >
                      <v-text-field
                        ref="twitter_id"
                        v-model="cast_prop.twitter_id"
                        name="twitter_id"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.twitter_id.text"
                        outlined
                        prefix="https://twitter.com/"
                        :counter="items.twitter_id.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold"
                  >公式Blueskyアカウント</span
                ></v-col
              >
              <v-col cols="12" sm="10">
                <span
                  >※該当するセラピストのBlueskyアカウントを登録してください。</span
                ><br />
                <span
                  >※アカウント名のみ入力してください。'@'(アットマーク)は必要ありません。</span
                >
                <v-row class="mt-2">
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.bluesky_id.text"
                      :rules="items.bluesky_id.rules"
                    >
                      <v-text-field
                        ref="bluesky_id"
                        v-model="cast_prop.bluesky_id"
                        name="bluesky_id"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.bluesky_id.text"
                        outlined
                        prefix="https://bsky.app/profile/"
                        suffix=".bsky.social"
                        :counter="items.bluesky_id.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold"
                  >公式instagramアカウント</span
                ></v-col
              >
              <v-col cols="12" sm="10">
                <span
                  >※該当するセラピストのinstagramアカウントを登録してください。</span
                ><br />
                <span
                  >※アカウント名のみ入力してください。'@'(アットマーク)は必要ありません。</span
                >
                <v-row class="mt-2">
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.instagram_id.text"
                      :rules="items.instagram_id.rules"
                    >
                      <v-text-field
                        ref="instagram_id"
                        v-model="cast_prop.instagram_id"
                        name="instagram_id"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.instagram_id.text"
                        outlined
                        prefix="https://www.instagram.com/"
                        :counter="items.instagram_id.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";
import selectItem from "@/common/selectItem.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const state = reactive({
      errorElement: null,
      cast_prop: {},
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
      nominate_fee_status_selected: null,
      joinDateDisableFlg: false,
      therapist_features_selected: [],
      therapist_features_type: {
        "therapist_features-id-1": { label: "明るい", value: "1" },
        "therapist_features-id-2": { label: "社交的", value: "2" },
        "therapist_features-id-3": { label: "天然", value: "3" },
        "therapist_features-id-4": { label: "清純", value: "4" },
        "therapist_features-id-5": { label: "照れ屋", value: "5" },
        "therapist_features-id-6": { label: "好奇心旺盛", value: "6" },
        "therapist_features-id-7": { label: "ツンデレ", value: "7" },
        "therapist_features-id-8": { label: "話上手", value: "8" },
        "therapist_features-id-9": { label: "聞き上手", value: "9" },
        "therapist_features-id-10": { label: "おとなしい", value: "10" },
        "therapist_features-id-11": { label: "真面目", value: "11" },
        "therapist_features-id-12": { label: "礼儀正しい", value: "12" },
        "therapist_features-id-13": { label: "色白美肌", value: "13" },
        "therapist_features-id-14": { label: "リピート高確率", value: "14" },
        "therapist_features-id-15": { label: "店長イチオシ", value: "15" },
        "therapist_features-id-16": { label: "好感度抜群", value: "16" },
        "therapist_features-id-17": { label: "丁寧な施術", value: "17" },
      },
      experience_type: [
        { value: null, text: "--" },
        { value: "1", text: "経験あり" },
        { value: "2", text: "業界未経験" },
        { value: "3", text: "完全未経験" },
      ],
      type_type: [
        { value: null, text: "--" },
        { value: "1", text: "セクシー" },
        { value: "2", text: "ロリ" },
        { value: "3", text: "清楚" },
        { value: "4", text: "お姉様" },
        { value: "5", text: "インテリ" },
        { value: "6", text: "癒し" },
        { value: "7", text: "モデル" },
        { value: "8", text: "綺麗" },
        { value: "9", text: "可愛い" },
      ],
    });

    const items = {
      "shop_message": {
        text: "お店のコメント",
        rules: "max:500",
        counter: "500",
      },
      "nominate_fee": {
        text: "指名料金設定",
        rules: "numeric|max:10",
        counter: "10",
      },
      "nominate_fee_status": {
        text: "セラピストページへの反映",
        rules: "",
        counter: "",
      },
      "join_date": {
        text: "入店日",
        rules: "",
        counter: "",
      },
      "experience": {
        text: "マッサージ経験",
        rules: "",
        counter: "",
      },
      "type": {
        text: "タイプ",
        rules: "",
        counter: "",
      },
      "therapist_features": {
        text: "特徴",
        rules: "",
        counter: "",
      },
      "treatment": {
        text: "得意な施術",
        rules: "max:50",
        counter: "50",
      },
      "hobby": {
        text: "趣味・マイブーム",
        rules: "max:50",
        counter: "50",
      },
      "twitter_id": {
        text: "",
        rules: "max:15",
        counter: "15",
      },
      "bluesky_id": {
        text: "",
        rules: "max:30",
        counter: "30",
      },
      "instagram_id": {
        text: "",
        rules: "max:30",
        counter: "30",
      },
    };

    const init = async () => {
      // cast_propにキーを設定
      for (let key in items) {
        set(state.cast_prop, key, null);
      }
      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        set(state.cast_prop, key, props.com_data.cast_prop[key]);
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);

      // セラピストの特徴をjsonから配列に変換しておく
      if (state.cast_prop.therapist_features) {
        state.therapist_features_selected = JSON.parse(
          state.cast_prop.therapist_features
        );
      }

      // 入店日の編集フラグ 一度設定すると変更できないため、すでに入力されている場合は入力不可にする
      if (state.cast_prop.join_date) {
        // 設定されている > 入力不可にして、バリデーションの条件を変更
        state.joinDateDisableFlg = true;
      }
      // セラピストページへの反映のチェックボックスの値設定
      if (state.cast_prop.nominate_fee_status === "True") {
        state.nominate_fee_status_selected = "1";
      }
      console.log(state.cast_prop);
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      state.errorElement = null;
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        const errorKey = utilFunc.getKeyByItemText(items, item_name);
        state.errorElement = ctx.refs[errorKey].$el;
      }
      return isValid;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    const save = async () => {
      // セラピストページへの反映のチェックボックスの値を設定
      if (state.nominate_fee_status_selected === "1") {
        set(state.cast_prop, "nominate_fee_status", "True");
      } else {
        set(state.cast_prop, "nominate_fee_status", "False");
      }
      for (let key in state.cast_prop) {
        if (key == "therapist_features") {
          set(
            state.cast_prop,
            key,
            state.therapist_features_selected.length == 0
              ? ""
              : JSON.stringify(state.therapist_features_selected)
          );
        }
      }
      // 更新前と比べて差分があれば更新処理
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          prop_param.push({
            cast_id: props.com_data.cast_id,
            key: props.com_data.prefix + "__" + key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:cast_manager_wordpress.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    return {
      props,
      setting,
      items,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
    };
  },
});
</script>

<style scoped></style>
