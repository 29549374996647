var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('only-site-card',{attrs:{"com_data":_vm.com_data},scopedSlots:_vm._u([{key:"item_edit",fn:function(){return [_c('ValidationObserver',{ref:"observer"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("メッセージ")]),_c('p',{staticClass:"red--text mini"},[_vm._v(" 未入力の場合、コメントタブの「"),_c('strong',[_vm._v("お店コメント")]),_vm._v("」の内容で更新します。 ")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.comment.text,"rules":_vm.items.comment.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"comment",attrs:{"name":"comment","error-messages":errors,"success":valid,"label":_vm.items.comment.text,"rows":"4","outlined":"","counter":_vm.items.comment.counter,"background-color":"white"},model:{value:(_vm.cast_prop.comment),callback:function ($$v) {_vm.$set(_vm.cast_prop, "comment", $$v)},expression:"cast_prop.comment"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Twitter")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.twitter.text,"rules":_vm.items.twitter.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"twitter",attrs:{"name":"twitter","error-messages":errors,"success":valid,"label":_vm.items.twitter.text,"persistent-hint":"","outlined":"","prefix":"@","hint":"ユーザー名の@を除いた半角英数字と(_)5~15字","counter":_vm.items.twitter.counter,"background-color":"white"},model:{value:(_vm.cast_prop.twitter),callback:function ($$v) {_vm.$set(_vm.cast_prop, "twitter", $$v)},expression:"cast_prop.twitter"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Instagram")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.instagram.text,"rules":_vm.items.instagram.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"instagram",attrs:{"name":"instagram","error-messages":errors,"success":valid,"label":_vm.items.instagram.text,"persistent-hint":"","outlined":"","hint":"インスタグラムのユーザーネーム","counter":_vm.items.instagram.counter,"background-color":"white"},model:{value:(_vm.cast_prop.instagram),callback:function ($$v) {_vm.$set(_vm.cast_prop, "instagram", $$v)},expression:"cast_prop.instagram"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("セラピスト歴")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3"}},[_c('v-select',{ref:"experiences",attrs:{"name":"experiences","items":_vm.experiencesItem,"no-data-text":_vm.items.experiences.text,"label":_vm.items.experiences.text,"success":"","outlined":"","background-color":"white"},model:{value:(_vm.cast_prop.experiences),callback:function ($$v) {_vm.$set(_vm.cast_prop, "experiences", $$v)},expression:"cast_prop.experiences"}})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("入店日")])]),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"entry_day",attrs:{"name":"entry_day","type":"date","success":true,"label":_vm.items.entry_day.text,"outlined":"","background-color":"white"},model:{value:(_vm.cast_prop.entry_day),callback:function ($$v) {_vm.$set(_vm.cast_prop, "entry_day", $$v)},expression:"cast_prop.entry_day"}})],1)],1)],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }